import React, { useEffect, useState } from "react";
import "../styles/about-us.css";
import { Container, Row, Col } from "reactstrap";
import v7 from "../assets/images/v7.jpeg";
import profile from "../assets/images/pexels-photo.jpg";
import contactus from "../assets/images/contact-us2.png";
import Header from "../components/Header/Header";
import axios from "axios";
import { BASE_URL } from "../utils/config";
import { useTranslation } from 'react-i18next';

const About = () => {
    const [contactInfo, setContactInfo] = useState([])
    const [phoneNumbers, setPhoneNumbers] = useState([])
    const [emails, setEmails] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/contactInfo/`);
                setContactInfo(response.data);
                const emailsFromBackend = response.data.map(contact => contact.email);
                setEmails(emailsFromBackend);
                const phonesFromBackend = response.data.map(contact => contact.phone);
                setPhoneNumbers(phonesFromBackend);
            } catch (error) {
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Header />
            <div className="p-0 m-0 heroSection-about">
                <i><h1>{t('AboutPage_ImageTitle')} </h1></i>
                <i><h5>{t('AboutPage_ImageSubTitle')} </h5></i>
            </div>
            {/* ============= profile section start ============= */}
            <section>
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className="hero__content">
                                <div className="hero__subtitle d-flex align-items-center">
                                    <h2>
                                        <span className="highlight fs-1">{t('AboutPage_AboutSection_Title')} </span>
                                    </h2>

                                </div>
                                <hr className="hrOrange" />
                                <p className="fs-4">
                                    <span className="smallHighlight">{t('AboutPage_AboutSection_MEA_Travel')} </span>  {t('AboutPage_AboutSection_Description1')}
                                </p>
                                <p>
                                    {t('AboutPage_AboutSection_Description2_Today')}, <span className="smallHighlight">{t('AboutPage_AboutSection_MEA_Travel')} </span> {t('AboutPage_AboutSection_Description2')}
                                </p>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="hero__img-box">
                                <img src={profile} alt="" className="border-0" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* ============= profile section start ============= */}
            {/* ============= Mission section start ============= */}
            <section style={{ backgroundColor: "#D8E8DE" }} className="box p-0 m-0 pb-5">
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className="hero__img-box">
                                <img src={v7} alt="" className="border-0" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="hero__content">
                                <div className="hero__subtitle d-flex align-items-center">
                                    <h2>
                                        <span className="highlight fs-1">{t('AboutPage_StatmentSection_Title')} </span>
                                    </h2>

                                </div>
                                <hr className="hrOrange" />
                                <p className="fs-4">
                                    "
                                    {t('AboutPage_StatmentSection_Description')}
                                    "
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* ============= Mission section End ============= */}
            {/* ============= Why us section start ============= */}
            <section>
                <Container>
                    <div>
                        <br />
                        <h1 className="highlight">{t('AboutPage_DifferentSection_Title')} </h1>
                        <hr className="hrOrange" />
                        <br />
                        <div class="grid-container w-100">
                            <div class="grid-item first">
                                <h1>{t('AboutPage_DifferentSection_Item1')} </h1>
                                {/* <br/>
                                <p>With a high bar for our integrity and dedication to customer service. Our strong company culture is anchored by our mission and values.</p> */}
                            </div>
                            <div class="grid-item second">
                                <h1>{t('AboutPage_DifferentSection_Item2')} </h1>
                                {/* <br/>
                                <p>We rank among the best agencies in the country. Our passion for our profession has resulted in middle east.</p> */}
                            </div>
                            <div class="grid-item third">
                                <h1>{t('AboutPage_DifferentSection_Item3')} </h1>
                                {/* <br/>
                                <p>Our management team is active on various travel advisory boards and committees for travel organizations.</p> */}
                            </div>
                            <div class="grid-item fourth">
                                <h1>{t('AboutPage_DifferentSection_Item4')} </h1>
                                {/* <br/>
                                <p>MEA Travel is a top member of the prestigious Signature Travel Network and a highly regarded worldwide partnership allowing us to provide our customers unmatched benefits.</p> */}
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            {/* ============= why us section end ============= */}
            {/* ============= Contact Info section start ============= */}
            <section style={{ backgroundColor: "#D8E8DE" }} className="box p-0 m-0 pb-5">
            </section>
            <section>
                <Container>
                    <div>
                        <Container>
                            <Row>
                                <Col lg="5">
                                    <img src={contactus} alt="" width='70%' className="pt-0 mt-0" />
                                </Col>
                                <Col>
                                    <Row>
                                        <br />
                                        <h1 className="highlight">{t('AboutPage_ContactUsSection_Title')} </h1>
                                        <hr className="w-50" style={{ color: '#db9e36' }} />
                                        <br />
                                    </Row>
                                    <Row className="pt-5">
                                        <Col lg="6">
                                            <div>
                                                <h3 className="">{t('AboutPage_ContactUsSection_Call')} </h3>
                                                {phoneNumbers && phoneNumbers.map((phone, index) => (
                                                    <li key={index} className="styled-list-about">
                                                        +20 {phone}
                                                    </li>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div>
                                                <h3 className="">{t('AboutPage_ContactUsSection_Email')} </h3>
                                                {emails && emails.map((email, index) => (
                                                    <li key={index} className="styled-list-about">
                                                        {email}
                                                    </li>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>

                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            {/* ============= Contact Info section End ============= */}
            {/* <Newsletter /> */}
        </>
    );
};

export default About;
