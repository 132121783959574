import React from 'react'
import Header from '../components/Header/Header'
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import '../styles/Page404.css';

export const Page404 = () => {
  const navigate = useNavigate()
  return (
    <>
      <Header />
      <section className="page404-container">
        <div className="error-container">
          <h1>404</h1>
          <p>Oops! The page you're looking for is not here.</p>
          <a href="/home"  >
            <Button className='button'>Go Back To Home</Button>
          </a>
        </div>
      </section>
    </>
  );
}
