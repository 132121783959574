import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import "./hero-carousel.css";
import { useTranslation } from 'react-i18next';

const ToursCarousel = (args) => {
  const { images, text, scrollToSearchBar } = args
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { t } = useTranslation();
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems([
      {
        src: images[0],
        altText: text,
        key: 1,
      },
      {
        src: images[1],
        altText: text,
        key: 2,
      },
      {
        src: images[2],
        altText: text,
        key: 3,
      },
    ])
  }, [images])

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img
          src={item.src}
          alt={item.altText}
          style={{ width: "-webkit-fill-available", height: "calc(100vh - 20.70vh)" }}
        />
        {/* <CarouselCaption
          captionHeader={item.altText}
        /> */}
        <button className="btn buttonBookNow" onClick={scrollToSearchBar}>
          {t('Tours_ImageButton')}
        </button>
      </CarouselItem>
    );
  });

  return (
    <>
      {items &&
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          {...args}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      }
    </>
  );
};

export default ToursCarousel;
