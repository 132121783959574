import React, { useEffect, useState } from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";

import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../utils/config";
import { useTranslation } from 'react-i18next';

const FeaturedTourList = () => {
  const {
    data: featuredTours,
    loading,
    error,
  } = useFetch(`${BASE_URL}/tours/search/getFeaturedTours`) || [];
  const [tourData, setTourData] = useState([])

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (featuredTours?.length > 0){
      setTourData(Object.keys(featuredTours).map(key => ({
        _id: featuredTours[key]?._id,
        photos: featuredTours[key]?.photos,
        price: featuredTours[key]?.price,
        priceForTravelAgents: featuredTours[key ]?.priceForTravelAgents,
        reviews: featuredTours[key]?.reviews,
        featured: featuredTours[key]?.featured,
        title: currentLanguage === 'Ar' ? featuredTours[key]?.title_Ar : currentLanguage === 'Es' ? featuredTours[key]?.title_Es : featuredTours[key]?.title,
        city: currentLanguage === 'Ar' ? featuredTours[key]?.city_Ar : currentLanguage === 'Es' ? featuredTours[key]?.city_Es : featuredTours[key]?.city_En,
      })));
    }

  }, [featuredTours, currentLanguage]);
  

  return (
    <>
      {loading && <h4>Loading......</h4>}
      {error && <h4>Loading......</h4>}
      {!loading &&
        !error &&
        tourData.length>0 && tourData?.map((tour) => (
          <Col lg="3" md='6' sm='6' className="mb-4" key={tour._id}>
            <TourCard tour={tour} />
          </Col>
        ))}
    </>
  );
};

export default FeaturedTourList;
