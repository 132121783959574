import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../styles/login.css";
import loginImg from "../assets/images/login.png";
import userIcon from "../assets/images/user.png";
import { AuthContext } from "./../context/AuthContext";
import { BASE_URL } from "./../utils/config";
import Header from "../components/Header/Header";
import { EmailAddress } from "../context/EmailAddress";
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { i18n } = useTranslation();

  const { t } = useTranslation();
  const { setEmailAddress } = useContext(EmailAddress);
  const [showModal, setShowModal] = useState(false);

  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined,
  });

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    dispatch({ type: "LOGIN_START" });
    try {
      const res = await fetch(`${BASE_URL}/auth/login`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(credentials),
      });

      const result = await res.json();
      if (!res.ok) alert(result.message);

      else {
        if(result?.data?.accountType == "TravelAgent"){
          if(result?.data?.travelAgent_Verification === true){
            dispatch({ type: "LOGIN_SUCCESS", payload: result.data });
            navigate("/");
            window.location.reload();
          }
          else setShowModal(true)
        }
        else{
          if (result?.data?.is_email_verified === true) {
            dispatch({ type: "LOGIN_SUCCESS", payload: result.data });
            navigate("/");
          }
          else {
            setEmailAddress(credentials.email)
            navigate("/verifyAccount");
          }
        }
      }
    } catch (error) {
      dispatch({ type: "LOGIN_FAILURE", paylod: error.message });
    }
  };

  return (
    <>
      <Header />
      <section>
        <Container>
          <Row>
            <Col lg="8" className="m-auto">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img">
                  <img src={loginImg} alt="" />
                </div>
                <div className="login__form">
                  <div className="user">
                    <img src={userIcon} alt="" />
                  </div>
                  <h2>{t('LoginPage_Title')} </h2>
                  <Form onSubmit={handleClick}>
                    <FormGroup>
                      <input
                        type="email"
                        placeholder={t('Email_PlaceHolder')}
                        required
                        id="email"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="password"
                        placeholder={t('Password_PlaceHolder')}
                        required
                        id="password"
                        onChange={handleChange}
                      />
                      <div className="forgotPassword w-100 d-flex justify-content-end">
                        <Link to="/forgotPassword">{t('LoginPage_ForgotPassword_Link')} </Link>
                      </div>

                    </FormGroup>
                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      {t('LoginPage_Button')}
                    </Button>
                  </Form>
                  <p>
                   {t("Don't have any account?")} <Link to="/register">{t('Register_Link')} </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal show={showModal} onHide={()=>setShowModal(false)} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: '#db9e36' }}>
          <Modal.Title>{t('Welcome')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          backgroundColor: "#fafafa",
          maxHeight: '60vh',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          msOverflowStyle: 'auto',
        }}>
          <div className=''>
            {i18n.language === 'Ar' ?
              <div>
                <div>{t('Header_TravelAgent_MSG_Not_Vrefied_Header')}</div>
                <span> {t('Header_TravelAgent_MSG_Not_Vrefied_2')}</span>
                {' '}
                <span>{t('Header_TravelAgent_MSG_Not_Vrefied_1')}</span>
                <div> {t('Header_TravelAgent_MSG_Not_Vrefied_3')}</div>
                <div>{t('Header_TravelAgent_MSG_Not_Vrefied_4')}</div>
              </div>
              :
              <div>
                <h4 className="text-center">{t('Header_TravelAgent_MSG_Not_Vrefied_Header')}</h4>
                <span>{t('Header_TravelAgent_MSG_Not_Vrefied_1')}</span>
                <span> {t('Header_TravelAgent_MSG_Not_Vrefied_2')}</span>
                <span> {t('Header_TravelAgent_MSG_Not_Vrefied_3')}</span>

                <div className="mt-3">{t('Header_TravelAgent_MSG_Not_Vrefied_4')}</div>
              </div>
            }

          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f4f4f4" }}>
          <Button className='border-0' style={{ backgroundColor: "#db9e36" }}
            onClick={()=>setShowModal(false)}>
            {t('Okay')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
