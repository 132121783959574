import React from 'react'
import '../styles/home.css'
import { Container, Row, Col } from 'reactstrap'
import Subtitle from './Subtitle';
import Testimonials from '../components/Testimonial/Testimonials';
import { useTranslation } from 'react-i18next';

const WhatOurFansSay = () => {
  const { t } = useTranslation();
    return (
        <Container>
        <Row>
          <Col lg="12">
            <Subtitle subtitle={t('ReviewsSection_Title')} />
            <h2 className="testimonial__title">{t('ReviewsSection_SubTitle')}</h2>
          </Col>
          <Col lg="12" className='h-100'>
            <Testimonials />
          </Col>
        </Row>
      </Container>
    )
}

export default WhatOurFansSay;
