import React, { useState, useContext, useEffect } from "react";
import "../styles/bookingPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { BASE_URL } from "../utils/config";
import Header from "../components/Header/Header";
import axios from "axios";
import { Container, Row, Col, FormGroup, Button } from "reactstrap";
import { Form } from "reactstrap";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from "reactstrap";
import { useTranslation } from 'react-i18next';
import defaultImg from "../assets/images/about.jpg"

const BookingPage = () => {
  const tourID = useParams()
  const [tour, setTour] = useState('')
  const [countries, setCountries] = useState([])
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tourData, setTourData] = useState({})

  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const [booking, setBooking] = useState({
    userId: user && user._id,
    userEmail: user && user.email,
    tourName: tour && tour.originalTitle,
    fullName: "",
    phone: "",
    guestSize: "",
    bookAt: "",
    nationality: "",
    rooms: [{ _id: 0, bedTypes: "", children: 0, adults: 0 }],
  });

  const [formError, setFormError] = useState({
    fullName: null,
    phone: null,
    guestSize: null,
    nationality: null,
    bookAt: null,
    ...booking.rooms.reduce((acc, _, index) => ({
      ...acc,
      [`room${index + 1}`]: {
        bedTypes: null, children: null, adults: null,
      },
    }), {}),
  });

  useEffect(() => {
    setLoading(true);
    axios.get((`${BASE_URL}/tours/${tourID.id}`))
      .then(res => {
        const { photos, ...tourDataWithoutPhotos } = res.data.data;
        setTour(tourDataWithoutPhotos)
        console.log(photos)
        if (photos.every(photo => photo === null)) {
          setTour(prev => ({ ...prev, photos: [defaultImg] }));
        } else {
          setTour(prev => ({ ...prev, photos }));
        }
        setBooking((prev) => ({ ...prev, tourName: res.data.data.title }))
      }).catch(err => setError(error)).finally(setLoading(false))

    axios.get('https://restcountries.com/v3.1/all')
      .then(res => {
        const countryNames = res.data.map(country => country.name.common);
        setCountries(countryNames);
      })
      .catch(err => { })
  }, [])

  useEffect(() => {
    setTourData({
      photos: tour.photos,
      price: tour.price,
      priceForTravelAgents: tour.priceForTravelAgents,
      maxGroupSize: tour.maxGroupSize,
      originalTitle: tour.title,
      title: currentLanguage === 'En' ? tour.title : currentLanguage === 'Ar' ? tour.title_Ar : tour.title_Es,
      desc: currentLanguage === 'En' ? tour.desc_En : currentLanguage === 'Ar' ? tour.desc_Ar : tour.desc_Es,
      address: currentLanguage === 'En' ? tour.address_En : currentLanguage === 'Ar' ? tour.address_Ar : tour.address_Es,
      city: currentLanguage === 'En' ? tour.city_En : currentLanguage === 'Ar' ? tour.city_Ar : tour.city_Es,
    })
    console.log('tourData',tourData)
    console.log('tour',tour)
  }, [tour, currentLanguage])

  const handleChange = (e) => {
    setBooking((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleRoomChange = (e, roomIndex) => {
    const { id, value } = e.target;
    const updatedRooms = [...booking.rooms];
    updatedRooms[roomIndex] = {
      ...updatedRooms[roomIndex],
      [id]: value,
    };
    setBooking((prevState) => ({
      ...prevState,
      rooms: updatedRooms,
    }));
  };

  const serviceFee = 10;
  const totalAmount =
    Number(tourData.price) * Number(booking.guestSize) + Number(serviceFee);

  const handleClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const hasEmptyFields = Object.values(booking).some(value => String(value).trim() === '');
    if (hasEmptyFields) {
      const roomErrors = booking.rooms?.map((room, index) => ({
        bedTypes: room.bedTypes.trim() === '' ? t('This field is required') : null,
        children: room.children === 0 ? t('This field is required') : null,
        adults: room.adults === 0 ? t('This field is required') : null,
      }));
      const allRoomErrors = roomErrors.reduce((acc, roomError, index) => ({
        ...acc,
        [`room${index + 1}`]: roomError,
      }), {});

      setFormError({
        fullName: String(booking.fullName).trim() === '' ? 'Full Name is required' : null,
        phone: String(booking.phone).trim() === '' ? 'Phone Number is required' : null,
        guestSize: String(booking.guestSize).trim() === '' ? 'Guest Size is required' : null,
        nationality: String(booking.nationality).trim() === '' ? 'Nationality is required' : null,
        bookAt: String(booking.bookAt).trim() === '' ? 'Travel Date is required' : null,
        ...allRoomErrors,
      });
      return;
    }

    try {
      if (!user || user === undefined || user === null) {
        return alert("Please sign in");
      }
      const res = await fetch(`${BASE_URL}/booking`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(booking),
      });
      const result = await res.json();

      if (!res.ok) {
        return alert(result?.message);
      }
      navigate("/thank-you");
    } catch (error) {
      alert(error.message)
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === tourData?.photos?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? tourData?.photos?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = tourData?.photos ? tourData?.photos?.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item}
      >
        <img
          src={item || ''}
          alt='image'
          style={{ width: "-webkit-fill-available", maxHeight: "450px" }}
        />
        {/* <CarouselCaption
          captionText="ss"
          captionHeader="ss"
        /> */}
      </CarouselItem>
    );
  }) : [];

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="body-page">
      <Header />
      <Container style={{ position: 'relative', paddingTop: "20px" }}>
        {loading && <h4 className="text-center pt-5">Loading.......</h4>}
        {error && <h4 className="text-center pt-5">{error}</h4>}
        {!loading && !error && (
          <Row>
            <Col>
              <div className="tour__content">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                // {...args}
                >
                  <CarouselIndicators
                    items={tourData?.photos ? tourData?.photos : ['']}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </Carousel>
              </div>
              <div className="pageTitle">
                <h1>{t('BookingPage_Slider_Title')} {tourData?.city}</h1>
                <h4>
                  {t('BookingPage_Slider_SubTitle')}
                </h4>
              </div>

            </Col>
          </Row>
        )}
      </Container>

      <div className="booking-page">
        {/*============== booking Form Start ============== */}
        <div className="booking__form-page me-1">
          <h5 className="p-0">
            {t('BookingPage_BookingForm_Title')}
          </h5>
          <Form className="booking__info-form-page"
            onSubmit={handleClick}
          >
            <FormGroup className="mb-3">
              <input
                type="text"
                placeholder={t('BookingPage_BookingForm_FullNamePlaceHolder')}
                id="fullName"
                required
                onChange={handleChange}
              />
              {formError.fullName && <p className="text-danger">{formError.fullName}</p>}
            </FormGroup>
            <FormGroup className="mb-3">
              <input
                type="number"
                placeholder={t('BookingPage_BookingForm_PhonePlaceHolder')}
                id="phone"
                min={0}
                required
                onChange={handleChange}
              />
              {formError.phone && <p className="text-danger">{formError.phone}</p>}
            </FormGroup>
            <FormGroup className="mb-3">
              <select
                style={{
                  width: '100%', padding: '0.5rem', borderRadius: '0.5rem',
                  color: 'var(--heading-color)',
                  fontStyle: '1rem',
                  border: 'none',
                  borderBottom: '1px solid rgb(229, 231, 235)'
                }}
                id="nationality"
                onChange={handleChange}
                required
              >
                <option value=""> {t('BookingPage_BookingForm_NationalityPlaceHolder')} </option>
                {countries.map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
              {formError.nationality && <p className="text-danger">{formError.nationality}</p>}
            </FormGroup>
            <FormGroup>
              <div className="w-100">
                <input
                  type="number"
                  placeholder={t('BookingPage_BookingForm_GuestPlaceHolder')}
                  id="guestSize"
                  value={booking.guestSize}
                  min={1}
                  max={tourData?.maxGroupSize || 100}
                  required
                  onChange={handleChange}
                />
                {formError.guestSize && <p className="text-danger">{formError.guestSize}</p>}
              </div>
            </FormGroup>
            <FormGroup className="">
              <label>{t('What is Your Start Date?')}</label>
              <div className="w-100">
                <input
                  type="date"
                  id="bookAt"
                  required
                  onChange={handleChange}
                />
                {formError.bookAt && <p className="text-danger">{formError.bookAt}</p>}

              </div>
            </FormGroup>
            {/* Room Info */}
            <FormGroup style={{ marginBottom: '3vh' }}>
              <label>{t('Modal_Tap2_1')}</label>
              <input
                type="number"
                placeholder={t('Enter number of rooms')}
                value={booking.rooms.length}
                onChange={(e) => {
                  const newRoomsCount = parseInt(e.target.value);
                  if (!isNaN(newRoomsCount) && newRoomsCount >= 0) {
                    // Update the number of rooms
                    const newRooms = Array.from({ length: newRoomsCount }, (_, index) => ({
                      _id: index,
                      bedTypes: '',
                      adults: 0,
                      children: 0,
                    }));
                    setBooking((prevState) => ({
                      ...prevState,
                      rooms: newRooms,
                    }));
                  }
                }}
                min={1}
              />
            </FormGroup>
            {booking?.rooms?.map((_, roomIndex) => (
              <div key={roomIndex} style={{}}>
                <p className="mb-1">Room {roomIndex + 1}</p>
                <div className="room" style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #6c757d', borderRadius: '1vh', padding: '0.5rem', marginBottom: '3vh', }}>
                  <FormGroup className="roomFormGroup" style={{ width: '35%' }}>
                    {/* <label>{t('Bedding Preference')}</label> */}
                    <select
                      as="select"
                      style={{
                        width: '100%', padding: '0.5rem', borderRadius: '0.5rem',
                        color: 'var(--heading-color)',
                        fontStyle: '1rem',
                        border: 'none',
                        borderBottom: '1px solid rgb(229, 231, 235)'
                      }}
                      value={booking.rooms[roomIndex]?.bedTypes || ''}
                      id="bedTypes"
                      onChange={(e) => handleRoomChange(e, roomIndex)}
                    >
                      <option value="">{t('Modal_Tap2_2')}</option>
                      <option value="double">{t('Double')}</option>
                      <option value="twin">{t('Twin')}</option>
                    </select>
                    {formError[`room${roomIndex + 1}`]?.bedTypes && (
                      <div className="text-danger">{formError[`room${roomIndex + 1}`]?.bedTypes}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="roomFormGroup" style={{ width: '30%' }}>
                    {/* <label>{t('How may adults?')}</label> */}
                    <input
                      type="number"
                      placeholder={t('Modal_Tap2_3')}
                      value={booking.rooms[roomIndex]?.adults || ''}
                      id="adults"
                      onChange={(e) => handleRoomChange(e, roomIndex)}
                      min={0}
                    />
                    <div style={{ fontSize: 'smaller' }}>{t('Ages 18 or above')}</div>
                    {formError[`room${roomIndex + 1}`]?.adults && (
                      <div className="text-danger">{formError[`room${roomIndex + 1}`]?.adults}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="roomFormGroup" style={{ width: '30%' }}>
                    {/* <label>{t('How may children?')}</label> */}
                    <input
                      type="number"
                      placeholder={t('How may children?')}
                      value={booking.rooms[roomIndex]?.children || ''}
                      id="children"
                      onChange={(e) => handleRoomChange(e, roomIndex)}
                      min={0}
                    />
                    <div style={{ fontSize: 'smaller' }}>{t('Ages 0-17')}</div>
                    {formError[`room${roomIndex + 1}`]?.children && (
                      <div className="text-danger">{formError[`room${roomIndex + 1}`]?.children}</div>
                    )}
                  </FormGroup>
                </div>

              </div>
            ))}
            <Button className="btn primary__btn-page w-100"
              onClick={handleClick}
            >
              {" "}
              {t('BookingPage_BookingForm_Button')}
            </Button>
          </Form>
        </div>
        {/*============== booking Form End ============== */}
        <div className="tour__info-page ms-1">
          <h2 style={{ color: "rgb(0, 190, 220)", }}>
            {tourData?.title}
          </h2>
          <br />
          <div className="align-items-center gap-5">
            <p className="p-0 m-0">
              {t('BookingPage_TourDataSection_Address')}
            </p>
            <div className="mb-2">
              <i className="ri-pin-distance-fill"
                style={{ color: "var(--secondary-color)" }}
              ></i>
              {tourData?.address}, {tourData?.city}
            </div>
            <p className="p-0 m-0">
              {t('BookingPage_TourDataSection_GroupNumber')}
            </p>
            <div>
              <i className="ri-group-line" style={{ color: "var(--secondary-color)" }}></i>
              {tourData?.maxGroupSize} {' '}
              {t('People')}
            </div>
            <div className="btn primary__btn-page w-100 bg-color-dark mt-5">
              <div>
                <i className="ri-money-dollar-circle-line" style={{ color: "var(--secondary-color)" }}></i>
                {t('Tour_BeforePrice')}
                {' '}
                {user?.accountType === 'TravelAgent' ?
                  <>
                    <span style={{ textDecoration: ' line-through', display: 'inline-block', fontSize: 'smaller' }}>${tourData?.price}</span>
                    {' '} ${tourData?.priceForTravelAgents}
                  </>
                  :
                  <span style={{ color: 'white', display: 'inline' }}>${tourData?.price} </span>
                }
                {/* ${tourData?.price}  */}

                {t('Tour_AfterPrice')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Newsletter /> */}
    </div >

  );
};

export default BookingPage;
