import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import ava01 from "../../assets/images/Pyramids.jpg";
import ava02 from "../../assets/images/temple.jpg";
import ava03 from "../../assets/images/nile.jpg";
import "./hero-carousel.css";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { useTranslation } from 'react-i18next';

const HeroCarousel = (args) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([
    {
      src: ava01,
      altText: t(`Home_Slider_Title1`),
      caption: t(`Home_Slider_SubTitle1`),
      key: 1,
    },
    {
      src: ava02,
      altText: t(`Home_Slider_Title2`),
      caption: t(`Home_Slider_SubTitle2`),
      key: 2,
    },
    {
      src: ava03,
      altText: t(`Home_Slider_Title3`),
      caption: t(`Home_Slider_SubTitle3`),
      key: 3,
    },
  ])

  useEffect(() => {
    axios.get(`${BASE_URL}/home`)
      .then(res => {
        const landing = res.data[0].landingSection
        const newItems = landing.map((item, index) => ({
          src: item.landingImage,
          altText: t(`Home_Slider_Title${index + 1}`),
          caption: t(`Home_Slider_SubTitle${index + 1}`),
          key: index,
        }));
        setItems(newItems);
      })
      .catch()
  }, [i18n.language])

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img
          src={item.src}
          alt={item.altText}
          style={{ width: "-webkit-fill-available", height: "calc(100vh - 9.70vh)" }}
        />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.altText}
        />
      </CarouselItem>
    );
  });

  return (
    <>
      {items &&
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          {...args}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      }
    </>
  );
};

export default HeroCarousel;
