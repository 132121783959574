import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../styles/login.css";
import registerImg from "../assets/images/register.png";
import userIcon from "../assets/images/user.png";
import { AuthContext } from "./../context/AuthContext";
import { BASE_URL } from "./../utils/config";
import Header from "../components/Header/Header";
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

const Register = () => {
  const { t } = useTranslation();
  const [credentials, setCredentials] = useState({
    username: undefined,
    email: undefined,
    password: undefined,
    accountType: 'Individual'
  });
  const [showModal, setShowModal] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch({ type: "REGISTER_SUCCESS" });
    navigate("/login");
  }

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${BASE_URL}/auth/register`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
      const result = await res.json();

      if (!res.ok) alert(result.message);

      else {
        if (credentials?.accountType == "TravelAgent") {
          setShowModal(true);
        }
        else {
          dispatch({ type: "REGISTER_SUCCESS" });
          navigate("/login");
        }
      }

    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section>
        <Container>
          <Row>
            <Col lg="8" className="m-auto">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img">
                  <img src={registerImg} alt="" />
                </div>
                <div className="login__form">
                  <div className="user">
                    <img src={userIcon} alt="" />
                  </div>
                  <h2>{t('RegisterPage_Title')} </h2>
                  <Form onSubmit={handleClick}>
                    <FormGroup>
                      <input
                        type="text"
                        placeholder={t('Username_PlaceHolder')}
                        required
                        id="username"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="email"
                        placeholder={t('Email_PlaceHolder')}
                        required
                        id="email"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="password"
                        placeholder={t('Password_PlaceHolder')}
                        required
                        id="password"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <select
                        style={{
                          width: '100%', padding: '0.5rem', borderRadius: '0.5rem',
                          color: 'grey',
                          fontStyle: '1rem',
                          border: 'none',
                          borderBottom: '1px solid rgb(229, 231, 235)'
                        }}
                        id="accountType"
                        onChange={handleChange}
                        required
                      >
                        <option value='' disabled selected>{t('AccountType_PlaceHolder')} </option>
                        <option value={"Individual"} style={{ color: 'black' }}>{t('AccountType_Item1')} </option>
                        <option value={"TravelAgent"} style={{ color: 'black' }}>{t('AccountType_Item2')} </option>
                      </select>
                    </FormGroup>
                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      {t('RegisterPage_Button')}
                    </Button>
                  </Form>
                  <p>
                    {t('Already have any account?')}  <Link to="/login">{t('Login_Link')} </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: '#db9e36' }}>
          <Modal.Title>{t('Welcome')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          backgroundColor: "#fafafa",
          maxHeight: '60vh',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          msOverflowStyle: 'auto',
        }}>
          <div className=''>
            {i18n.language === 'Ar' ?
              <div>
                <h4>{t('Header_TravelAgent_MSG_Not_Vrefied_Header')}</h4>
                <span>{t('Header_TravelAgent_MSG_Not_Vrefied_1')}</span>
                <div> {t('Header_TravelAgent_MSG_Not_Vrefied_2')}</div>
                {' '}
                
                <div> {t('Header_TravelAgent_MSG_Not_Vrefied_3')}</div>
                <div className="mt-3">{t('Header_TravelAgent_MSG_Not_Vrefied_4')}</div>
              </div>
              :
              <div>
                <h4 className="text-center">{t('Header_TravelAgent_MSG_Not_Vrefied_Header')}</h4>
                <span>{t('Header_TravelAgent_MSG_Not_Vrefied_1')}</span>
                <span> {t('Header_TravelAgent_MSG_Not_Vrefied_2')}</span>
                <span> {t('Header_TravelAgent_MSG_Not_Vrefied_3')}</span>

                <div className="mt-3">{t('Header_TravelAgent_MSG_Not_Vrefied_4')}</div>
              </div>
            }

          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f4f4f4" }}>
          <Button className='border-0' style={{ backgroundColor: "#db9e36" }}
            onClick={handleCloseModal}>
            {t('Okay')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Register;
