import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./../pages/Home";
import Tours from "./../pages/Tours";
import TourDetails from "./../pages/TourDetails";
import Login from "./../pages/Login";
import Register from "./../pages/Register";
import SearchResultList from "./../pages/SearchResultList";
import { ThankYou } from "../pages/ThankYou";
import BookingPage from "../pages/BookingPage";
import About from "../pages/About";
import { Page404 } from "../pages/404Page";
import Gallery from "../pages/Gallery";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import VerifyAccount from "../pages/VerifyAccount";
import ChangePassword from "../pages/ForgotPassword/ChangePassword";
import VerifyAccountToEdit from "../pages/ForgotPassword/VerifyAccountToEdit";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/tours" element={<Tours />} />
      <Route path="/tours/egypt" element={<Tours />} />
      <Route path="/tours/uae" element={<Tours />} />
      <Route path="/tours/ksa" element={<Tours />} />
      <Route path="/tours/:id" element={<TourDetails />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/verifyAccount" element={<VerifyAccount />} />
      <Route path="/verifyAccountToEdit" element={<VerifyAccountToEdit />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/register" element={<Register />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/tours/search" element={<SearchResultList />} />
      <Route path="/booking/:id" element={<BookingPage/>}/>
      <Route path="/gallery" element={<Gallery/>}/>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Routers;
