import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../styles/login.css";
import VerifyAccountImage from "../assets/images/VerifyAccount.png";
import userIcon from "../assets/images/user.png";
import { AuthContext } from "../context/AuthContext";
import { BASE_URL } from "../utils/config";
import Header from "../components/Header/Header";
import { EmailAddress } from "../context/EmailAddress";
import axios from "axios";

const VerifyAccount = () => {
  const { dispatch } = useContext(AuthContext);
  const { emailAddress } = useContext(EmailAddress);

  const [code, setcode] = useState({
    email: emailAddress,
    verificationCode: undefined,
  });


  const navigate = useNavigate();

  const handleChange = (e) => {
    setcode((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/auth/verifyAccount`, code)
      .then(res => {
        alert('Account verified. You can now login.');
        navigate("/login");
      })
      .catch(err => {
        alert(err);
      })

  };

  const sendVerificationCode = async (e) => {
    e.preventDefault();

    await axios.post(`${BASE_URL}/users/sendVerificationCodeByEmail`, {email: emailAddress})
      .then(res => {
        alert('Verification code send succssefully.');
      })
      .catch(err => {
        alert(err);
      })
  }

  return (
    <>
      <Header />
      <section>
        <Container>
          <Row>
            <Col lg="8" className="m-auto">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img d-flex align-items-center justify-content-center">
                  <img src={VerifyAccountImage} alt="" />
                </div>
                <div className="login__form">
                  <div className="user">
                    <img src={userIcon} alt="" />
                  </div>
                  <h2 className="mb-4">Verify Your Account</h2>
                  <Form onSubmit={handleClick}>
                    <FormGroup>
                      <p className="text-start mt-0">Enter the code that been send to your email</p>
                      <input
                        type="text"
                        placeholder="Enter Verification Code"
                        required
                        id="verificationCode"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Form>
                  <p>
                    Didn't recieve verification code? <Link onClick={sendVerificationCode}>Click here to send another code</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default VerifyAccount;
