import React, { useEffect, useState } from 'react'
import '../styles/home.css'
import { Container, Row, Col } from 'reactstrap'
import experienceImg from "../assets/images/experience.png";
import axios from "axios";
import { BASE_URL } from "../utils/config";
import Subtitle from './Subtitle';
import { useTranslation } from 'react-i18next';

const ExperienceSection = () => {
    const { t } = useTranslation();
    const [exp, setExp] = useState({})
    useEffect(() => {
        axios.get(`${BASE_URL}/home`)
          .then(res => {
            setExp(res.data[0].experience)
          },[])
      },[])


    return (
        <Container>
            <Row>
                <Col>
                    <div className="experience__content text-center">
                        <Subtitle subtitle={t('ExperienceSection_Tile')} />
                        <h2 className="featured__tour-title">
                            {t('ExperienceSection_SubTitle')}
                            {/* With Our all experience  */}
                            <br />
                            {/* we will serve you */}
                        </h2>
                        <p>
                        {t('ExperienceSection_Description')}
                            {/* is simply dummy text of the printing and typesetting industry.
                <br />
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s. */}
                        </p>
                    </div>
                    <div className="counter__wrapper d-flex align-items-center justify-content-center gap-5 pb-5">
                        <div className="counter__box">
                            <span>
                                {exp.successfullTrip}k+

                                {/* 12k+ */}
                            </span>
                            <h6>{t('ExperienceSection_Item1')}</h6>
                        </div>
                        <div className="counter__box">
                            <span>
                                {/* 2k+ */}
                                {exp.regularClients}k+
                            </span>
                            <h6>{t('ExperienceSection_Item2')}</h6>
                        </div>
                        <div className="counter__box">
                            <span>
                                {/* 15 */}
                                {exp.yearsExperience}
                            </span>
                            <h6>{t('ExperienceSection_Item3')}</h6>
                        </div>
                    </div>
                </Col>
                {/* <Col lg="6">
                    <div className="experience__img">
                        <img src={experienceImg} alt="" />
                    </div>
                </Col> */}
            </Row>
        </Container>
    )
}

export default ExperienceSection;
