import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Subtitle from "./../shared/Subtitle";
import Newsletter from "../shared/Newsletter";
import Header from "../components/Header/Header";
import { useTranslation } from 'react-i18next';

const Gallery = () =>{
  const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
        <Header />
        <Container>
            <Row>
                
            </Row>
        <Row className="mt-5">
          <Col lg="12">
          <Subtitle subtitle={t('GallerySection_Title')} />
              <h2 className="gallery__title">
                {t('GallerySection_SubTitle')}
              </h2>
          </Col>
          <Col lg="12">
            <MasonryImagesGallery />
          </Col>
        </Row>
        {/* <Row className="mt-5 pt-5">
            <Newsletter />
        </Row> */}
      </Container>
        </>
    )
}

export default Gallery