import { useEffect, useState } from "react";
import Routers from "../../router/Routers";
import Footer from "./../Footer/Footer";
import CallUsBar from "../callus-bar/CallUsBar";
import { EmailAddress } from "../../context/EmailAddress";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LanguageContext } from "../../context/Language";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      En: {
        translation: require("../../locals/En.json")
      },
      Ar: {
        translation: require("../../locals/Ar.json")
      },
      Es: {
        translation: require("../../locals/Es.json")
      }
    },
    lng: localStorage.getItem('meaTravelLanguage') || 'En',
    fallbackLng: "En",
    interpolation: {
      escapeValue: false
    }
  });

const Layout = () => {
  const [emailAddress, setEmailAddress] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem('meaTravelLanguage') || 'En');
  useEffect(() => {
    document.title = "MEA-Travel";
  }, []);

  return (
    <>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <div style={{ direction: language === "Ar" ? "rtl" : "ltr" }}>
          <EmailAddress.Provider value={{ emailAddress, setEmailAddress }}>
            <Routers />
            <Footer />
            <CallUsBar />
          </EmailAddress.Provider>
        </div>
      </LanguageContext.Provider>
    </>
  );
};

export default Layout;
