import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { t } from 'i18next';
import { Form } from 'react-bootstrap';
// import CircleIcon from '@mui/icons-material/Circle';

const CustomModal = ({ roomNull, show, handleClose, handleChange, handleRoomChange, handleClickSend, handleClickNext, handleClickPrevious, activeTab, setActiveTab, cutomiseTripData, setCutomiseTripData, formErrors }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton style={{ backgroundColor: '#db9e36' }}>
        <Modal.Title>{t('Customise Your Trip')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        backgroundColor: "#fafafa",
        maxHeight: '60vh',
        overflowY: 'scroll',
        scrollbarWidth: 'auto',
        msOverflowStyle: 'auto',
      }}>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} style={{ marginBottom: '3vh' }}>
          <Tab eventKey="basicData" title={<span style={{ color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
            {t('Modal_Tap1_title')}
            {formErrors.destination || formErrors.date || formErrors.tripLength ?
              // <CircleIcon className="icon" style={{ fontSize: 'small', color: 'red' }} />
              <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%' }}></div>
              :
              ''}
          </span>}
          >
            <Form.Group controlId="destination" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Modal_Tap1_1')}</Form.Label>
              <Form.Control
                as="select"
                value={cutomiseTripData.destination}
                onChange={handleChange}
              >
                <option value={0}> {t('Modal_Tap1_1_select_0')} </option>
                <option value='Egypt'> {t('EGYPT')} </option>
                <option value='UAE'> {t('UAE')} </option>
                <option value='KSA'> {t('KSA')} </option>
              </Form.Control>
              {formErrors.destination && <div className="error">{formErrors.destination}</div>}
            </Form.Group>
            <Form.Group controlId="date" className='mb-2'>
              <Form.Label>{t('Modal_Tap1_2')}</Form.Label>
              <Form.Control
                type="date"
                value={cutomiseTripData.date}
                onChange={handleChange}
              />
              {formErrors.date && <div className="error">{formErrors.date}</div>}
            </Form.Group>
            <Form.Group controlId="flexible" style={{ marginBottom: '3vh' }}>
              <Form.Check
                type='checkbox'
                label={t('Modal_Tap1_3')}
                checked={cutomiseTripData.flexible}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="tripLength">
              <Form.Label>{t('Modal_Tap1_4')}</Form.Label>
              <Form.Control
                type="number"
                // placeholder={t('What’s your ideal trip length?')}
                value={cutomiseTripData.tripLength}
                onChange={handleChange}
                min={1}
              />
              {formErrors.tripLength && <div className="error">{formErrors.tripLength}</div>}

            </Form.Group>
          </Tab>
          <Tab eventKey="How many people are traveling?" title={<span style={{ color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
            {t('Modal_Tap2_title')}
            {roomNull === true ? (
              // <CircleIcon className="icon" style={{ fontSize: 'small', color: 'red' }} />
              <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%' }}></div>
            ) : null}
          </span>}>
            <Form.Group style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Modal_Tap2_1')}</Form.Label>
              <Form.Control
                type="number"
                placeholder={t('Modal_Tap2_1_1')}
                value={cutomiseTripData.rooms.length}
                onChange={(e) => {
                  const newRoomsCount = parseInt(e.target.value);
                  if (!isNaN(newRoomsCount) && newRoomsCount >= 0) {
                    // Update the number of rooms
                    const newRooms = Array.from({ length: newRoomsCount }, (_, index) => ({
                      _id: index,
                      bedTypes: '',
                      adults: 0,
                      children: 0,
                    }));
                    setCutomiseTripData((prevState) => ({
                      ...prevState,
                      rooms: newRooms,
                    }));
                  }
                }}
                min={1}
              />
            </Form.Group>

            {cutomiseTripData?.rooms?.map((_, roomIndex) => (
              <div key={roomIndex} style={{}}>
                <p>{t('Room')} {roomIndex + 1}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #6c757d', borderRadius: '1vh', padding: '0.5rem', marginBottom: '3vh', }}>
                  <Form.Group controlId={`bedTypes`} style={{ width: '30%' }}>
                    <Form.Label>{t('Modal_Tap2_2')}</Form.Label>
                    <Form.Control
                      as="select"
                      value={cutomiseTripData.rooms[roomIndex]?.bedTypes || ''}
                      onChange={(e) => handleRoomChange(e, roomIndex)}
                    >
                      <option value="">{t('Select')}</option>
                      <option value="double">{t('Double')}</option>
                      <option value="twin">{t('Twin')}</option>
                    </Form.Control>
                    {formErrors[`room${roomIndex + 1}`]?.bedTypes && (
                      <div className="error">{formErrors[`room${roomIndex + 1}`]?.bedTypes}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId={`adults`} style={{ width: '30%' }}>
                    <Form.Label>{t('Modal_Tap2_3')}</Form.Label>
                    <Form.Control
                      type="number"
                      // placeholder={t('How may adults?')}
                      value={cutomiseTripData.rooms[roomIndex]?.adults || ''}
                      onChange={(e) => handleRoomChange(e, roomIndex)}
                      min={0}
                    />
                    <div>{t('Ages 18 or above')}</div>
                    {formErrors[`room${roomIndex + 1}`]?.adults && (
                      <div className="error">{formErrors[`room${roomIndex + 1}`]?.adults}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId={`children`} style={{ width: '30%' }}>
                    <Form.Label>{t('How may children?')}</Form.Label>
                    <Form.Control
                      type="number"
                      // placeholder={t('How may children?')}
                      value={cutomiseTripData.rooms[roomIndex]?.children || ''}
                      onChange={(e) => handleRoomChange(e, roomIndex)}
                      min={0}
                    />
                    <div>{t('Ages 0-17')}</div>
                    {formErrors[`room${roomIndex + 1}`]?.children && (
                      <div className="error">{formErrors[`room${roomIndex + 1}`]?.children}</div>
                    )}
                  </Form.Group>
                </div>

              </div>
            ))}

          </Tab>

          <Tab eventKey="budget" title={<span style={{ color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
            {t('Modal_Tap3_title')}
            {formErrors.clientPreferences || formErrors.budget ?
              // <CircleIcon className="icon" style={{ fontSize: 'small', color: 'red' }} />
              <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%' }}></div>
              :
              ''}
          </span>}>
            <Form.Group controlId="budget" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Maximum Budget Per Person (USD)')}</Form.Label>
              <Form.Control
                type="number"
                // placeholder={t('Maximum Budget Per Person (USD)')}
                value={cutomiseTripData.budget}
                onChange={handleChange}
                min={1}
              />
              {formErrors.budget && <div className="error">{formErrors.budget}</div>}
            </Form.Group>
            <Form.Group controlId="includeInternationalFlights" style={{ marginBottom: '3vh' }}>
              <Form.Check
                type='checkbox'
                label={t('That budget include international flights')}
                checked={cutomiseTripData.includeInternationalFlights}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="clientPreferences" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t("What's most important to you?")}</Form.Label>
              <Form.Control
                as="select"
                value={cutomiseTripData.clientPreferences}
                onChange={handleChange}
              >
                <option value={0}> {t("Select")} </option>
                <option value='KeepBudget'> {t('Keeping to my budget')} </option>
                <option value='increaseBudget'> {t("For the right trip, I'll increase my budget")} </option>
                <option value='takingThePerfectTrip'> {t('Taking the perfect trip')} </option>
              </Form.Control>
              {formErrors.clientPreferences && <div className="error">{formErrors.clientPreferences}</div>}
            </Form.Group>
          </Tab>
          <Tab eventKey="userData" title={<span style={{ color: 'black', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
            {t('Modal_Tap4_title')}
            {formErrors.email || formErrors.firstName || formErrors.lastName || formErrors.phone ?
              // <CircleIcon className="icon" style={{ fontSize: 'small', color: 'red' }} />
              <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '50%' }}></div>
              :
              ''}
          </span>}>

            <Form.Group controlId="email" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Email')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('Email')}
                value={cutomiseTripData.email}
                onChange={handleChange}
              />
              {formErrors.email && <div className="error">{formErrors.email}</div>}
            </Form.Group>
            <Form.Group controlId="firstName" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Fisrt Name')}</Form.Label>

              <Form.Control
                type="text"
                placeholder={t('Fisrt Name')}
                value={cutomiseTripData.firstName}
                onChange={handleChange}
              />
              {formErrors.firstName && <div className="error">{formErrors.firstName}</div>}
            </Form.Group>
            <Form.Group controlId="lastName" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Last Name')}</Form.Label>

              <Form.Control
                type="text"
                placeholder={t('Last Name')}
                value={cutomiseTripData.lastName}
                onChange={handleChange}
              />
              {formErrors.firstName && <div className="error">{formErrors.firstName}</div>}
            </Form.Group>
            <Form.Group controlId="phone" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Phone Number')}</Form.Label>

              <Form.Control
                type="number"
                placeholder={t('Phone Number')}
                value={cutomiseTripData.phone}
                onChange={handleChange}
                min={1}
              />
              {formErrors.phone && <div className="error">{formErrors.phone}</div>}
            </Form.Group>
            <Form.Group controlId="additionalNotes" style={{ marginBottom: '3vh' }}>
              <Form.Label>{t('Any preferences you want to share as we prepare your custom itinerary?')}</Form.Label>
              <Form.Control
                type="textarea"
                placeholder={t('e.g. "must-see" or "must-do" activities')}
                value={cutomiseTripData.additionalNotes}
                onChange={handleChange}
                min={1}
              />
            </Form.Group>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#f4f4f4" }}>
        {activeTab !== 'basicData' && (
          <Button variant="secondary" onClick={handleClickPrevious}>
            {t('Previous')}
          </Button>
        )}
        <Button className='border-0' style={{ backgroundColor: "#db9e36" }}
          onClick={activeTab === 'userData' ? handleClickSend : handleClickNext}>
          {activeTab === 'userData' ? t('Finish') : t('Next')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
