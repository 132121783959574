import React from 'react'
import ServiceCard from './ServiceCard';
import {Col} from 'reactstrap';

import destinationImg from '../assets/images/destination.png'
import panningImg from '../assets/images/guide.png'
import localGuides from '../assets/images/tour-guide.png'
import supportimg from '../assets/images/support.png'
import { useTranslation } from 'react-i18next';

const ServiceList = () => {
    const { t } = useTranslation();
    const serviceData =[
        {
            imgUrl: destinationImg,
            title: t('RecieveSection_Item1_Title'),
            desc: t('RecieveSection_Item1_Description')
        },
        {
            imgUrl: panningImg,
            title: t('RecieveSection_Item2_Title'),
            desc: t('RecieveSection_Item2_Description')
        },
        {
            imgUrl: localGuides,
            title: t('RecieveSection_Item3_Title'),
            desc: t('RecieveSection_Item3_Description')
        },
        {
            imgUrl: supportimg,
            title: t('RecieveSection_Item4_Title'),
            desc: t('RecieveSection_Item4_Description')
        }
    ]

  return( <>
    {serviceData.map((item,index)=> (
        <Col lg='3' md='6' sm='12' className='mb-4' key={index}>
            <ServiceCard item={item} />
        </Col>
    ))}
  </>
 ); 
}

export default ServiceList;
