import React, { useContext, useEffect, useState } from "react";
import "../styles/home.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../assets/images/hero-img01.jpg";
import heroImg02 from "../assets/images/hero-img02.jpg";
import heroImg03 from "../assets/images/hero-img03.jpg";
import Subtitle from "./../shared/Subtitle";
import worldImg from "../assets/images/world.png";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import Header from "../components/Header/Header";
import HeroCarousel from "../components/Hero-Carousel/HeroCarousel";
import axios from "axios";
import { BASE_URL } from "../utils/config";
import ExperienceSection from "../shared/ExperienceSection";
import WhatYoullReceive from "../shared/WhatYoullReceive";
import WhatOurFansSay from "../shared/WhatOurFansSay";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "../context/AuthContext";
import TripAdvisorWidget from "../components/TripAdvisorWidget";

const Home = () => {

  const [hero, setHero] = useState({})
  const [countries, setCountries] = useState({})
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext)

  useEffect(() => {
    axios.get(`${BASE_URL}/home`)
      .then(res => {
        setHero(res.data[0].heroSection)
      }, [])

    axios.get(`${BASE_URL}/countries`)
      .then(res => {
        setCountries(res.data)
      }, [])
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroCarousel />
      <Header />

      {/* ============= hero section start ============= */}
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center">
                  {/* <h2>
                    <span className="highlight">MEA-Travel,&nbsp;</span>
                  </h2> */}
                  <h3 className='section__subtilte w-100'>{t('Home_HeroSection_Title')}</h3>
                  {/* <Subtitle subtitle={"enjoy every moment."} /> */}
                  <img src={worldImg} alt="" />
                </div>
                {user?.accountType === "TravelAgent" ?
                  <div>
                      {i18n.language === 'Ar' ?
                        <div>
                          <div className='fw-bold'>{t('Header_TravelAgent_MSG_Vrefied_Header')}</div>
                          <div>{t('Header_TravelAgent_MSG_Vrefied_1')}</div>
                          <div> {t('Header_TravelAgent_MSG_Vrefied_2')}</div>
                          <div> <a style={{ color: '#DB9E36' }} href='mailto:sales@mea-travel.com'>sales@mea-travel.com</a> {' '}{t('Header_TravelAgent_MSG_Vrefied_3')}</div>
                        </div>
                        : i18n.language === 'En' ?
                          <div>
                            <div className='fw-bold'>{t('Header_TravelAgent_MSG_Vrefied_Header')}</div>
                            <div>{t('Header_TravelAgent_MSG_Vrefied_1')}</div>
                            <div> {t('Header_TravelAgent_MSG_Vrefied_2')}</div>
                            <div> {t('Header_TravelAgent_MSG_Vrefied_3')} {' '} <a style={{ color: '#DB9E36' }} href='mailto:sales@mea-travel.com'>sales@mea-travel.com</a></div>
                          </div>
                          :
                          <div>
                            <div className='fw-bold'>{t('Header_TravelAgent_MSG_Vrefied_Header')}</div>
                            <div>{t('Header_TravelAgent_MSG_Vrefied_1')}</div>
                            <div> {t('Header_TravelAgent_MSG_Vrefied_2')}</div>
                            <div> {t('Header_TravelAgent_MSG_Vrefied_3')}</div>
                            <div>{t('Header_TravelAgent_MSG_Vrefied_4')}</div>
                            <div> {t('Header_TravelAgent_MSG_Vrefied_5')}</div>
                            <div> {t('Header_TravelAgent_MSG_Vrefied_6')} {' '} <a style={{ color: '#DB9E36' }} href='mailto:sales@mea-travel.com'>sales@mea-travel.com</a> </div>
                          </div>
                      }
                  </div>
                  :
                  <>
                    <h2>
                      {t('Home_HeroSection_SubTitle')}{" "}
                    </h2>
                    <p>
                      {t('Home_HeroSection_Description')}
                    </p>
                  </>
                }

              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box">
                <img
                  // src={heroImg} 
                  src={(countries && countries[1]?.image) || heroImg}
                  alt="" />
                <button className="btn country__btn" style={{ opacity: 0.8 }}>
                  <Link to={`/tours/uae`} title='UAE'>
                    {t('UAE')}
                  </Link>
                </button>
              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box mt-4">
                {/* <video src={heroVideo} alt="" controls autoPlay loop muted /> */}
                <img
                  // src={heroImg03} 
                  src={(countries && countries[0]?.image) || heroImg03}
                  alt="" />
                <button className="btn country__btn" style={{ opacity: 0.8 }}>
                  <Link to={`/tours/egypt`} title="Egypt">
                    {t('EGYPT')}
                  </Link>
                </button>
              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box mt-5">
                <img
                  // src={heroImg02}
                  src={(countries && countries[2]?.image) || heroImg02}
                  alt="" />
                <button className="btn country__btn" style={{ opacity: 0.8 }}>
                  <Link to={`/tours/ksa`} title="KSA">
                    {t('KSA')}
                  </Link>
                </button>
              </div>
            </Col>
            {/* <SearchBar /> */}
          </Row>
        </Container>
      </section>
      {/* ============= hero section start ============= */}
      <section style={{ backgroundColor: "#D8E8DE" }} className="box">
        <WhatYoullReceive />
      </section>
      {/* ======== featured tour section start ======== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={t('ExploreSection_Title')} />
              <h2 className="featured__tour-title">{t('ExploreSection_SubTitle')}</h2>
            </Col>
            <FeaturedTourList />
          </Row>
        </Container>
      </section>
      {/* ======== featured tour section end ======== */}

      {/* ======== experience tour section start ======== */}
      <section style={{ backgroundColor: "#D8E8DE " }} className="box">
        <ExperienceSection />
      </section>
      {/* ======== experience tour section end ======== */}

      {/* ======== testmonial section start ======== */}
      <section>
        <WhatOurFansSay />
      </section>
      {/* ======== testmonial section End ======== */}
      {/* ======== trip advisor section start ======== */}
      <section>
        <TripAdvisorWidget />
      </section>
      {/* ======== testmonial section end ======== */}

      {/* ======== gallery section start ======== */}
      {/* <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={t('GallerySection_Title')} />
              <h2 className="gallery__title">
                {t('GallerySection_SubTitle')}
              </h2>
            </Col>
            <Col lg="12">
              <MasonryImagesGallery />
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* ======== gallery section end ======== */}
      {/* <Newsletter /> */}
    </>
  );
};

export default Home;
