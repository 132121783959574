import React, { useRef } from "react";
import "./search-bar.css";
import { Col, Form, FormGroup } from "reactstrap";
import { BASE_URL } from "../utils/config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
  const { t } = useTranslation();
  const countryRef = useRef("");
  const locationRef = useRef("");
  const maxGrpupSizeRef = useRef(0);
  const navigate = useNavigate();

  const searchHandler = async () => {
    const country = countryRef.current.value;
    const location = locationRef.current.value;
    const maxGrpupSize = maxGrpupSizeRef.current.value;

    if (country === "" || location === "" || maxGrpupSize === "") {
      return alert("All fields are required!");
    }

    const res = await fetch(
      `${BASE_URL}/tours/search/getTourBySearch?city=${location}&distance=${country}&maxGroupSize=${maxGrpupSize}`
    );

    if (!res.ok) alert("Something went wrong");

    const result = await res.json();

    navigate(
      `/tours/search?city=${location}&distance=${country}&maxGroupSize=${maxGrpupSize}`,
      { state: result.data }
    );
  };
  return (
    <Col lg="12">
      <div className="search__bar">
        <Form className="d-flex align-items-center ">
          <FormGroup className="d-flex gap-3 form__group form__group-fast">
            <span>
              <i className="ri-map-pin-time-line"></i>
            </span>

            <div className="w-100">
              <h6>
              {t('Tours_Search_Left_Title') } 
              </h6>
              <div>{t('Tours_Search_Left_Description')}</div>
              {/* <input
                type="text"
                placeholder={t('Tours_Search_Left_Description')}
                ref={countryRef}
                disabled
                style={{backgroundColor: 'white'}}
              /> */}
            </div>
          </FormGroup>
          <FormGroup className="d-flex gap-3 form__group form__group-fast">
            <span>
              <i className="ri-user-star-line"></i>
            </span>
            <div>
              <h6>
                {t('Tours_Search_Center_Title')}
              </h6>
              <div>{t('Tours_Search_Center_Description')}</div>
              {/* <input
                type="text"
                placeholder={t('Tours_Search_Center_Description')}
                ref={locationRef}
                disabled
                style={{backgroundColor: 'white'}}
              /> */}
            </div>
          </FormGroup>
          <FormGroup className="d-flex gap-3 form__group form__group-last">
            <span>
              <i className="ri-equalizer-fill"></i>
            </span>
            <div>
              <h6>
                {t('Tours_Search_Right_Title')}
              </h6>
              <div>{t('Tours_Search_Right_Description')}</div>
              {/* <input
                type="number"
                placeholder={t('Tours_Search_Right_Description')}
                ref={maxGrpupSizeRef}
                disabled
                style={{backgroundColor: 'white'}}
              /> */}
            </div>
          </FormGroup>
          {/* <span className='search__icon' type='submit' onClick={searchHandler}>
                    <i class='ri-search-line'></i>
                </span> */}
        </Form>
      </div>
    </Col>
  );
};

export default SearchBar;
