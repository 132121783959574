import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/login.css";
import forgotPasswordImage from "../../assets/images/forgotPassword.png";
import userIcon from "../../assets/images/user.png";
import { AuthContext } from "../../context/AuthContext";
import { BASE_URL } from "../../utils/config";
import Header from "../../components/Header/Header";
import axios from "axios";
import { EmailAddress } from "../../context/EmailAddress";
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const { setEmailAddress } = useContext(EmailAddress);
  const [email, setemail] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    setemail(e.target.value);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    await axios.post(`${BASE_URL}/users/sendVerificationCodeByEmail`, { email: email })
      .then(res => {
        alert('Verification code send succssefully.');
        setEmailAddress(email)
        navigate('/VerifyAccountToEdit')
      })
      .catch(err => {
        alert(err.response.data.message);
      })
  };

  return (
    <>
      <Header />
      <section>
        <Container>
          <Row>
            <Col lg="8" className="m-auto">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img d-flex align-items-center justify-content-center">
                  <img src={forgotPasswordImage} alt="" />
                </div>
                <div className="login__form">
                  <div className="user">
                    <img src={userIcon} alt="" />
                  </div>
                  <h2 className="mb-4">{t('ForgotPasswordPage_Title')}  </h2>
                  <Form onSubmit={handleClick}>
                    <FormGroup>
                      <p className="text-start mt-0">{t('ForgotPasswordPage_Info')}</p>
                      <input
                        type="email"
                        placeholder={t('Email_PlaceHolder')}
                        required
                        id="email"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      {t('ForgotPasswordPage_Button')}
                    </Button>
                  </Form>
                  <p>
                    {t("Don't have any account?")} <Link to="/register">{t('Register_Link')}</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgotPassword;
