import React from 'react'
import Slider from 'react-slick'
import ava01 from '../../assets/images/ava-1.jpg'
import ava02 from '../../assets/images/ava-2.jpg'
import ava03 from '../../assets/images/ava-3.jpg'
import ava04 from '../../assets/images/ava-4.jpg'


const Testimonials = () => {

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    }

    return (
        <Slider {...settings} style={{ overflow: 'hidden', hieght: '100%' }}>
            <div className="testimonial py-4 px-3">
                <p>Brilliant activity! Well worth the money, and a fab thing to do! I would advise doing the sunset tour if you are confident with driving in the dark. Armando was brilliant, welcoming and caring throughout the whole trip!</p>
                <div className='d-flex align-items-center gap-4 mt-3'>
                    <img src={ava01} className='w-25 h-25 rounded-2' alt='' />
                    <div>
                        <h6 className='mb-0 mt-3'>Youssef Mokhtar</h6>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
            <div className="testimonial py-4 px-3">
                <p>Absolutny worth the price. Suitable for families with kids. We have a great time. The team was exceptional and helpful. Multi language service. Don’t waste a time in the hotel swimming pool just go quads! 5*</p>
                <div className='d-flex align-items-center gap-4 mt-3'>
                    <img src={ava02} className='w-25 h-25 rounded-2' alt='' />
                    <div>
                        <h6 className='mb-0 mt-3'>Reem Taher</h6>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
            <div className="testimonial py-4 px-3">
                <p>David is a really good tour guide. All in all it is a very eventful experience you shouldn’t miss out on.</p>
                <div className='d-flex align-items-center gap-4 mt-3'>
                    <img src={ava03} className='w-25 h-25 rounded-2' alt='' />
                    <div>
                        <h6 className='mb-0 mt-3'>Ahmed Othman</h6>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
            <div className="testimonial py-4 px-3">
                <p>It was well organised, Rana was so friendly and very helpful… she looked after us so well. May god bless her.</p>
                <div className='d-flex align-items-center gap-4 mt-3'>
                    <img src={ava04} className='w-25 h-25 rounded-2' alt='' />
                    <div>
                        <h6 className='mb-0 mt-3'>Alaa Saeed</h6>
                        <p>Customer</p>
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default Testimonials;
