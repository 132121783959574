import React, { useState, useContext } from "react";
import "./booking.css";
import { Form, FormGroup, ListGroup, ListGroupItem, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { BASE_URL } from "../../utils/config";
import { useTranslation } from 'react-i18next';

const Booking = ({ tour, avgRating }) => {
  const { price, reviews, title, priceForTravelAgents } = tour;
  const navigate = useNavigate();
  const { id } = useParams()
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [booking, setBooking] = useState({
    userId: user && user._id,
    userEmail: user && user.email,
    tourName: title,
    fullName: "",
    phone: "",
    guestSize: 1,
    bookAt: "",
  });

  const handleChange = (e) => {
    setBooking((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const serviceFee = 10;
  const totalAmount =
    Number(price) * Number(booking.guestSize) + Number(serviceFee);

  const handleClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    // try {
    //   if (!user || user === undefined || user === null) {
    //     return alert("Please sign in");
    //   }
    //   const res = await fetch(`${BASE_URL}/booking`, {
    //     method: "post",
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //     credentials: "include",
    //     body: JSON.stringify(booking),
    //   });
    //   const result = await res.json();

    //   if (!res.ok) {
    //     return alert(result.message);
    //   }
    //   navigate("/thank-you");
    // } catch (error) {
    //   alert(error.message);
    // }
    navigate(`/booking/${id}`)
  };

  return (
    <div
      className="booking"
      style={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        backgroundColor: "#D8E8DE",
      }}
    >
      <div className="booking__top d-flex align-items-center justify-content-between">
        {t('Tour_BeforePrice')}
        <h3>
          {user?.accountType === 'TravelAgent' ?
            <span>
              <span style={{ textDecoration: ' line-through', fontSize: 'smaller' }}>${price} </span>
              {' '} {' '}
              <h4 style={{ fontWeight: 'bold', color: 'black', display: 'inline' }}>${priceForTravelAgents} </h4>
            </span>
            :
            <h4 style={{ fontWeight: 'bold', color: 'black', display: 'inline' }}>${price} </h4>
          }

          <span>{t('Tour_AfterPrice')}</span>
        </h3>
        <span className="tour__rating d-flex align-items-center">
          <i class="ri-star-fill"></i> {avgRating === 0 ? null : avgRating} (
          {reviews?.length})
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: "inherit",
        }}
      >
        <i class="ri-check-double-line">
          <span
            style={{
              paddingLeft: "0.5rem",
              fontFamily: "var(--bs-body-font-family)",
            }}
          >
            {t('TourDescriptionPage_Right_BookingCard_CheckItem1')}
          </span>
        </i>
        <i class="ri-check-double-line">
          <span
            style={{
              paddingLeft: "0.5rem",
              fontFamily: "var(--bs-body-font-family)",
            }}
          >
            {t('TourDescriptionPage_Right_BookingCard_CheckItem2')}
          </span>
        </i>
      </div>
      {/*============== booking Form Start ============== */}
      {/* <div className="booking__form">
        <h5>Information</h5>
        <Form className="booking__info-form" onSubmit={handleClick}>
          <FormGroup>
            <input
              type="text"
              placeholder="Full Name"
              id="fullName"
              required
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <input
              type="number"
              placeholder="Phone"
              id="phone"
              required
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup className="d-flex align-items-center gap-3">
            <input
              type="date"
              placeholder=""
              id="bookAt"
              required
              onChange={handleChange}
            />
            <input
              type="number"
              placeholder="Guest"
              id="guestSize"
              required
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
      </div> */}
      {/*============== booking Form End ============== */}

      {/*==============booking bottom ============== */}
      <div
        className="booking__bottom"
        style={{ backgroundColor: "#367c7a!important;", marginTop: "0.5rem" }}
      >
        {/* <ListGroup>
          <ListGroupItem className="border-0 px-0">
            <h5 className="d-flex align-items-center gap-1">
              ${price}
              <i class="ri-close-line"></i> 1 person
            </h5>
            <span>${price}</span>
          </ListGroupItem>
          <ListGroupItem className="border-0 px-0">
            <h5>Service Charge</h5>
            <span>${serviceFee}</span>
          </ListGroupItem>
          <ListGroupItem className="border-0 px-0 total">
            <h5>Total</h5>
            <span>${totalAmount}</span>
          </ListGroupItem>
        </ListGroup> */}
        <Button className="btn primary__btn w-100 mt-4" onClick={handleClick}>
          {" "}
          {t('TourDescriptionPage_Right_BookingCard_Button')}
        </Button>
      </div>
    </div>
  );
};

export default Booking;
