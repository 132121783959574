import React, { useEffect, useRef, useState, useContext } from "react";
import "../styles/tour-details.css";
import {
  Container,
  Row,
  Col,
  Form,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { useParams } from "react-router-dom";
import calculateAvgRating from "../utils/avgRating";
import avatar from "../assets/images/avatar.jpg";
import Booking from "../components/Booking/Booking";
import Newsletter from "./../shared/Newsletter";
import Header from "../components/Header/Header";
import useFetch from "./../hooks/useFetch";
import { BASE_URL } from "./../utils/config";
import { AuthContext } from "../context/AuthContext";
import notFound from "../assets/images/notFound.jpg";
import axios from "axios";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const TourDetails = (args) => {
  const { id } = useParams();
  const reviewMsgRef = useRef("");
  const [tourRating, setTourRating] = useState(null);
  const { user } = useContext(AuthContext);
  const [tourData, setTourData] = useState({})

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  //Fetch data from database
  const { data: tour, loading, error } = useFetch(`${BASE_URL}/tours/${id}`);

  // let {
  //   photos,
  //   title,
  //   desc,
  //   price,
  //   address,
  //   reviews,
  //   city,
  //   distance,
  //   maxGroupSize,
  //   glances,
  //   itinerary,
  //   accommodations,
  //   notes,
  //   inclusions,
  //   highlights
  // } = tour;

  useEffect(() => {
    if (currentLanguage === 'En') {
      setTourData({
        photos: tour.photos, price: tour.price, priceForTravelAgents: tour.priceForTravelAgents,
        reviews: tour.reviews, maxGroupSize: tour.maxGroupSize, maplink: tour.maplink || notFound,
        title: tour.title, desc: tour.desc_En, address: tour.address_En, city: tour.city_En,
        glances: tour?.glances?.map(glance => ({
          glanceDay: glance.glanceDay,
          glanceOrder: glance.glanceOrder,
          glanceTitle: glance.glanceTitle_En,
          glanceItem: glance.glanceItem_En
        })),
        itinerary: tour?.itinerary?.map(itr => ({
          itrday: itr.itrday,
          itrtitle: itr.itrtitle_En,
          itrdesc: itr.itrdesc_En,
        })),
        accommodations: tour?.accommodations?.map(item => ({
          accommodationTitle: item.accommodationTitle_En,
          accommodationDesc: item.accommodationDesc_En,
        })),
        notes: tour?.notes?.map(item => ({
          noteDesc: item.noteDesc_En,
        })),
        inclusions: tour?.inclusions?.map(item => ({
          inclusionIncluded: item.inclusionIncluded,
          inclusionDesc: item.inclusionDesc_En,
        })),
        highlights: tour?.highlights?.map(item => ({
          highlights: item.highlights_En,
        })),
      })
    }
    else if (currentLanguage === 'Ar') {
      setTourData({
        photos: tour.photos, price: tour.price, priceForTravelAgents: tour.priceForTravelAgents,
        reviews: tour.reviews, maxGroupSize: tour.maxGroupSize, maplink: tour.maplink,
        title: tour.title_Ar, desc: tour.desc_Ar, address: tour.address_Ar, city: tour.city_Ar,
        glances: tour?.glances?.map(glance => ({
          glanceDay: glance.glanceDay,
          glanceOrder: glance.glanceOrder,
          glanceTitle: glance.glanceTitle_Ar,
          glanceItem: glance.glanceItem_Ar
        })),
        itinerary: tour?.itinerary?.map(itr => ({
          itrday: itr.itrday,
          itrtitle: itr.itrtitle_Ar,
          itrdesc: itr.itrdesc_Ar,
        })),
        accommodations: tour?.accommodations?.map(item => ({
          accommodationTitle: item.accommodationTitle_Ar,
          accommodationDesc: item.accommodationDesc_Ar,
        })),
        notes: tour?.notes?.map(item => ({
          noteDesc: item.noteDesc_Ar,
        })),
        inclusions: tour?.inclusions?.map(item => ({
          inclusionIncluded: item.inclusionIncluded,
          inclusionDesc: item.inclusionDesc_Ar,
        })),
        highlights: tour?.highlights?.map(item => ({
          highlights: item.highlights_Ar,
        })),
      })
    }
    else if (currentLanguage === 'Es') {
      setTourData({
        photos: tour.photos, price: tour.price, priceForTravelAgents: tour.priceForTravelAgents,
        reviews: tour.reviews, maxGroupSize: tour.maxGroupSize, maplink: tour.maplink || 'ss',
        title: tour.title_Es, desc: tour.desc_Es, address: tour.address_Es, city: tour.city_Es,
        glances: tour?.glances?.map(glance => ({
          glanceDay: glance.glanceDay,
          glanceOrder: glance.glanceOrder,
          glanceTitle: glance.glanceTitle_Es,
          glanceItem: glance.glanceItem_Es
        })),
        itinerary: tour?.itinerary?.map(itr => ({
          itrday: itr.itrday,
          itrtitle: itr.itrtitle_Es,
          itrdesc: itr.itrdesc_Es,
        })),
        accommodations: tour?.accommodations?.map(item => ({
          accommodationTitle: item.accommodationTitle_Es,
          accommodationDesc: item.accommodationDesc_Es,
        })),
        notes: tour?.notes?.map(item => ({
          noteDesc: item.noteDesc_Es,
        })),
        inclusions: tour?.inclusions?.map(item => ({
          inclusionIncluded: item.inclusionIncluded,
          inclusionDesc: item.inclusionDesc_Es,
        })),
        highlights: tour?.highlights?.map(item => ({
          highlights: item.highlights_Es,
        })),
      })
    }
  }, [tour, currentLanguage])

  let photos = tourData.photos?.filter(item => item !== null)

  const [glancesData, setGlancesData] = useState([])
  const [itrData, setItrData] = useState([])

  useEffect(() => {
    const rearrangedData = [];

    if (tourData?.glances?.length > 0) {
      for (const item of tourData?.glances) {
        const index = item.glanceDay - 1;
        if (!rearrangedData[index]) {
          rearrangedData[index] = [];
        }
        rearrangedData[index].push(item);
      }
    }
    setGlancesData(rearrangedData)
  }, [tourData.glances])

  useEffect(() => {
    const rearrangedData = [];

    if (tourData?.itinerary?.length > 0) {
      for (const item of tourData?.itinerary) {
        const index = item.itrday - 1;
        if (!rearrangedData[index]) {
          rearrangedData[index] = [];
        }
        rearrangedData[index].push(item);
      }
    }
    setItrData(rearrangedData)
  }, [tourData.itinerary])

  const [inclusionIncluded, setinclusionIncluded] = useState([])
  const [inclusionNotIncluded, setinclusionNotIncluded] = useState([])
  const [reviewsData, setReviewsData] = useState('')
  const [reviewsRating, setReviewsRating] = useState('')

  useEffect(() => {
    if (tourData?.inclusions?.length > 0) {
      setinclusionIncluded(tourData.inclusions.filter(item => item.inclusionIncluded === true));
      setinclusionNotIncluded(tourData.inclusions.filter(item => item.inclusionIncluded === false));
    }
  }, [tourData.inclusions])

  useEffect(() => {
    const fetchReviewsData = async () => {
      try {
        const requests = tourData?.reviews?.map(async reviewId => {
          const response = await axios.get(`${BASE_URL}/review/${reviewId}`);
          return response.data;
        });

        const reviewsDatas = await Promise.all(requests);
        const ratings = reviewsDatas.map(review => review.data);
        setReviewsData(reviewsDatas)
        setReviewsRating(ratings);
      } catch (error) {
      }
    };
    fetchReviewsData();
  }, [tourData.reviews]);

  const { totalRating, avgRating } = calculateAvgRating(reviewsRating);

  //Date Format:
  const options = { day: "numeric", month: "long", year: "numeric" };

  //Submition Request
  const submitHandler = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const reviewText = reviewMsgRef.current.value;

    try {
      if (!user || user === undefined || user === null) {
        alert("Please Sign in");
      }

      const reviewObj = {
        username: user?.username,
        reviewText,
        rating: tourRating,
      };

      const res = await fetch(`${BASE_URL}/review/${id}`, {
        method: "post",
        headers: { "content-type": "application/json" },
        credentials: "include",
        body: JSON.stringify(reviewObj),
      });

      const result = await res.json();
      if (!res.ok) {
        return alert(result.message);
      }
      alert(result.message);
    } catch (error) {
      alert(error.message);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === photos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? photos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = photos ? photos.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item}
      >
        <img
          src={item}
          alt='image'
          style={{ width: "-webkit-fill-available", height: "450px" }}
        />
        <CarouselCaption
        />
      </CarouselItem>
    );
  }) : [];

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tour]);

  return (
    <>
      <Header />
      <section style={{ paddingTop: "20px" }}>
        <Container>
          {loading && <h4 className="text-center pt-5">Loading.......</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}
          {!loading && !error && (
            <Row>
              <Col lg="8">
                <div className="tour__content">
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    {...args}
                  >
                    <CarouselIndicators
                      items={photos ? photos : []}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {slides}
                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={previous}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={next}
                    />
                  </Carousel>

                  <div className="tour__info">
                    <h2 style={{ color: "rgb(0, 190, 220)" }}>{tourData?.title}</h2>
                    <div className="d-flex align-items-center gap-5">
                      <span>
                        {/* <i class="ri-pin-distance-fill"
                          style={{ color: "var(--secondary-color)" }}
                        ></i>  */}
                        <FontAwesomeIcon icon={faInfoCircle} style={{ color: "var(--secondary-color)" }} />
                        {tourData?.desc}
                      </span>
                      {/* <span>
                        <i class="ri-pin-distance-fill"
                          style={{ color: "var(--secondary-color)" }}
                        ></i> {address}
                      </span> */}
                      {/* <span>
                        <i
                          class="ri-award-fill"
                          style={{ color: "rgb(219, 158, 54)" }}
                        ></i>
                        Best Selling
                      </span> */}
                      {/* <span className="tour__rating d-flex align-items-center gap-1">
                        <i
                          class="ri-star-fill"
                          style={{ color: "var(--secondary-color)" }}
                        ></i>{" "}
                        {avgRating === 0 ? null : avgRating}
                        {totalRating === 0 ? (
                          "Not rated"
                        ) : (
                          <span>({reviews?.length})</span>
                        )}
                      </span> */}
                    </div>

                    <div className="tour__extra-details">
                      <span>
                        <i class="ri-map-pin-2-line icon" style={{ color: "var(--secondary-color)" }}></i>
                        {tourData?.city}, {tourData?.address}
                      </span>
                      <span>
                        <i class="ri-money-dollar-circle-line" style={{ color: "var(--secondary-color)" }}></i>
                        {t('Tour_BeforePrice')}
                        {user?.accountType === 'TravelAgent' ?
                          <span>
                            <span style={{textDecoration: ' line-through'}}>${tourData?.price} </span>
                            {' '}
                            <span>${tourData?.priceForTravelAgents} </span>
                          </span>
                          :
                          <span>${tourData?.price} </span>
                        }

                        {t('Tour_AfterPrice')}
                      </span>
                      {/* <span>
                        <i class="ri-map-pin-time-line" style={{ color: "var(--secondary-color)" }}></i>
                        {distance} k/m
                      </span> */}
                      {/* <span>
                        <i class="ri-group-line" style={{ color: "var(--secondary-color)" }}></i>
                        {maxGroupSize} people
                      </span> */}
                    </div>

                    <div style={{ marginBottom: "2.5rem" }}>
                      <h2 style={{ color: "rgb(219, 158, 54)" }}>
                        {t('TourDescriptionPage_Left_HighlightsTitle')}
                      </h2>
                      <ul>
                        {tourData?.highlights?.map((item, index) => {
                          return (
                            <li key={index}>
                              • {item.highlights}
                            </li>
                          )
                        })}
                        {/* <li>
                          • Glide down the Nile on a cruise with regular stops
                          for private excursions.
                        </li>
                        <li>
                          • Explore the Valley of the Kings, Valley of the
                          Queens, and King Tut’s tomb.
                        </li>
                        <li>
                          • Learn about the Pyramids of Giza with the help of an
                          experienced private Egyptologist guide.
                        </li>
                        <li>• See the Khan Khalili Bazaar at night</li>
                        <li>
                          • Stay at a handpicked selection of premium
                          accommodations.
                        </li>
                        <li style={{ fontStyle: "italic" }}>
                          • Please note: USD per person, double occupancy.
                          Custom designed personal tour. Prices subject to
                          change.{" "}
                        </li> */}
                      </ul>
                    </div>

                    <h5>
                      {t('TourDescriptionPage_Left_DescriptionTitle')}
                    </h5>

                    {/* <p>{desc}</p> */}

                    <div>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            style={{ color: "#db9e36", fontWeight: "bold" }}
                            className={activeTab == "1" ? "active" : ""}
                            onClick={() => setActiveTab("1")}
                          >
                            {t('At a glance')}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ color: "#db9e36", fontWeight: "bold" }}
                            className={activeTab == "2" ? "active" : ""}
                            onClick={() => setActiveTab("2")}
                          >
                            {t('Itinerary')}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ color: "#db9e36", fontWeight: "bold" }}
                            className={activeTab == "3" ? "active" : ""}
                            onClick={() => setActiveTab("3")}
                          >
                            {t('Accommodations')}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ color: "#db9e36", fontWeight: "bold" }}
                            className={activeTab == "4" ? "active" : ""}
                            onClick={() => setActiveTab("4")}
                          >
                            {t('Notes')}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ color: "#db9e36", fontWeight: "bold" }}
                            className={activeTab == "5" ? "active" : ""}
                            onClick={() => setActiveTab("5")}
                          >
                            {t('Inclusions')}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <div
                                className="timeline"
                                style={{ paddingTop: "1rem" }}
                              >
                                {glancesData.map((glance, index) => (
                                  <div key={index} className="mb-3">
                                    <li className="daydot">Day {glance[0].glanceDay}</li> {/* Adjust for one-based index */}
                                    <h6
                                      style={{
                                        paddingLeft: "1.8rem",
                                        color: "#00bedc",
                                      }}
                                    >
                                      {glance[0].glanceTitle}
                                    </h6>
                                    {glance.map((glance, innerIndex) => (
                                      <ul className="styled-list pb-0 mb-0" key={glance._id}>
                                        <li key={innerIndex}>{glance.glanceItem}</li>
                                      </ul>
                                    ))}
                                  </div>
                                ))}

                                {/* <li className="daydot">Day 1</li>
                                <h6
                                  style={{
                                    paddingLeft: "1.8rem",
                                    color: "#00bedc",
                                  }}
                                >
                                  CAIRO The Adventure Begins
                                </h6>
                                <ul>
                                  <li>• Airport Assistance & Visa Service</li>
                                  <li>• Private Airport Transfer</li>
                                  <li>• At Leisure to adjust your body time</li>
                                </ul> */}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <div style={{ paddingTop: "1rem" }}>

                              {itrData && itrData.map((day, index) => (
                                <div key={index}>
                                  <li style={{ fontWeight: "bold" }}>
                                    DAY {day[0].itrday}
                                  </li>
                                  {day.map((item, innerIndex) => (
                                    <ul className="styled-list pb-0 mb-0" style={{ paddingLeft: "1.4rem" }} key={innerIndex}>
                                      <h6
                                        style={{
                                          paddingTop: "0.6rem",
                                          color: "#00bedc",
                                        }}
                                      >
                                        - {item.itrtitle}
                                      </h6>
                                      <p
                                        style={{
                                          paddingLeft: "2rem",
                                          textAlign: "justify",
                                        }}
                                      >
                                        {item.itrdesc}
                                      </p>
                                    </ul>
                                  ))}
                                </div>
                              ))}

                              {/* <li style={{ fontWeight: "bold" }}>
                                DAY 1 : Cairo
                              </li>
                              <h6
                                style={{
                                  paddingLeft: "1.4rem",
                                  color: "#00bedc",
                                }}
                              >
                                The Adventure Begins
                              </h6>
                              <p
                                style={{
                                  paddingLeft: "1.4rem",
                                  textAlign: "justify",
                                }}
                              >
                                For your added comfort, upon your arrival at the
                                airport, an airport representative will greet
                                you in the tunnel and escort you through customs
                                and immigration. The representative will
                                facilitate the included visa process for you.
                                Continue to the baggage claim hall and passenger
                                pick up. Here an airport representative will
                                help you connect with your private transfer to
                                your hotel. Enjoy a private airport transfer
                                accompanied by an English-speaking
                                representative. Enjoy time at leisure to explore
                                at your own pace, or relax at your hotel.
                              </p> */}
                            </div>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <div style={{ paddingTop: "1rem" }}>
                              {tourData?.accommodations && tourData?.accommodations?.map((accommodation, index) => (
                                <div key={index}>
                                  <li style={{ fontWeight: "bold" }}>
                                    {accommodation.accommodationTitle}
                                  </li>
                                  <p
                                    style={{
                                      paddingLeft: "1.4rem",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {accommodation.accommodationDesc}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row>
                            <div style={{ paddingTop: "1rem" }}>
                              {tourData?.notes && tourData?.notes?.map((note, index) => (
                                <div key={index}>
                                  <li style={{ fontWeight: "bold" }}>
                                    {note.noteDesc}
                                  </li>
                                </div>
                              ))}
                            </div>
                          </Row>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            <div style={{ paddingTop: "1rem" }}>

                              <li style={{ fontWeight: "bold" }}>
                                {t('Included')}
                              </li>
                              {inclusionIncluded && inclusionIncluded.map((inclusion, index) => (
                                <ul key={index}>
                                  {inclusion.inclusionDesc}
                                </ul>
                              ))}

                              <br />
                              <li style={{ fontWeight: "bold" }}>
                                {t('Not Included')}
                              </li>
                              {inclusionNotIncluded && inclusionNotIncluded.map((inclusion, index) => (
                                <ul key={index}>
                                  {inclusion.inclusionDesc}
                                </ul>
                              ))}
                            </div>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>

                  {/* ============ Tour reviews Section start ========= */}
                  <div className="tour__reviews mt-4">
                    <h4>{t('TourDescriptionPage_Left_Reviews_Title')} ({tourData?.reviews?.length} {t('TourDescriptionPage_Left_Reviews_Title')})</h4>

                    <Form onSubmit={submitHandler}>
                      <div className="d-flex align-items-center gap-3 mb-4 rating__group">
                        <span onClick={() => setTourRating(1)}>
                          1<i className="ri-star-s-fill"></i>
                        </span>
                        <span onClick={() => setTourRating(2)}>
                          2<i className="ri-star-s-fill"></i>
                        </span>
                        <span onClick={() => setTourRating(3)}>
                          3<i className="ri-star-s-fill"></i>
                        </span>
                        <span onClick={() => setTourRating(4)}>
                          4<i className="ri-star-s-fill"></i>
                        </span>
                        <span onClick={() => setTourRating(5)}>
                          5<i className="ri-star-s-fill"></i>
                        </span>
                      </div>
                      <div className="review__input">
                        <input
                          type="text"
                          ref={reviewMsgRef}
                          placeholder={t('TourDescriptionPage_Left_Reviews_ButtonPlaceHolder')}
                          required
                        />
                        <button
                          className="btn primary__btn text-white"
                          type="submit"
                        >
                          {t('SubmitButton')}
                        </button>
                      </div>
                    </Form>
                    <ListGroup className="user__reviews">
                      {reviewsData && reviewsData.map((review, index) => (
                        <div className="review__item">
                          <img src={avatar} alt="" />

                          <div className="w-100" key={index}>
                            <div className="d-flex align-items-center justify-content-between"  >
                              <div>
                                <h5>{review.data.username}</h5>
                                <p>
                                  {new Date(
                                    review.data.createdAt
                                  ).toLocaleDateString("en-Us", options)}
                                </p>
                              </div>
                              <span className="d-flex align-items-center">
                                {review.data?.rating}
                                <i className="ri-star-s-fill"></i>
                              </span>
                            </div>
                            <h6>{review.data?.reviewText}</h6>
                            {review.data?.reply ?
                              <h6 style={{ paddingLeft: '30px', color: '#afafaf' }}><span style={{ color: '#d6a960' }}>Mea-Travel</span> {review.data?.reply}</h6>
                              :
                              ''
                            }
                          </div>
                        </div>
                      ))}
                    </ListGroup>
                  </div>
                  {/* ============ Tour reviews Section end========= */}
                </div>
              </Col>
              <Col lg="4">
                {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                <Booking tour={tour} avgRating={avgRating} />

                <div
                  style={{
                    zIndex: "-1",
                    marginTop: "1rem",
                    borderRadius: "10px",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <img
                    src={tourData?.maplink}
                    width=""
                    height="400"
                    alt=""
                    style={{ borderRadius: "12px", width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
      {/* <Newsletter /> */}
    </>
  );
};

export default TourDetails;
