import React, { useEffect, useState } from 'react'
import { BASE_URL } from "../../utils/config";
import axios from "axios";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import galleryImages from './galleryImages';

const MasonryImagesGallery = () => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    axios.get(`${BASE_URL}/gallery`)
    .then(res => {
        setData(res.data.data)
    })
    .catch(err => {})
  })
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{350:1, 768:3, 992:4}}>
        <Masonry gutter='1rem'>
            {
                data.map((item,index)=>(
                    <img className='masonry__img' src={item.image || ''} 
                    key={index} alt='' 
                    style={{'width':'100%', 'display':'block', 'borderRadius':'10px'}}
                    />
                ))
            }
        </Masonry>
    </ResponsiveMasonry>
    // <ResponsiveMasonry columnsCountBreakPoints={{350:1, 768:3, 992:4}}>
    //     <Masonry gutter='1rem'>
    //         {
    //             galleryImages.map((item,index)=>(
    //                 <img className='masonry__img' src={item || ''} 
    //                 key={index} alt='' 
    //                 style={{'width':'100%', 'display':'block', 'borderRadius':'10px'}}
    //                 />
    //             ))
    //         }
    //     </Masonry>
    // </ResponsiveMasonry>
  )
}

export default MasonryImagesGallery;
