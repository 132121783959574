import "./App.css";
import Layout from "./components/Layout/Layout";

function App() {
  const api_regex = /^\/api\/.*/
   if (api_regex.test(window.location.pathname)) {
      return <div />
   } else return <Layout/>;
}

export default App;
