import React from 'react'
import './service-card.css';

const ServiceCard = ({item}) => {
    const {imgUrl, title, desc} = item
  return (
    <div className='service__item'>
        <div className="service__img" style={{marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>
            <img src={imgUrl} alt='' />
        </div>
        <h5 style={{textAlign:"center"}}>{title}</h5>
        <p style={{textAlign:"center"}}>{desc}</p>
    </div>
  )
}

export default ServiceCard;
