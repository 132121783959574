import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/login.css";
import changePasswordImg from "../../assets/images/changePassword.png";
import userIcon from "../../assets/images/user.png";
import { BASE_URL } from "../../utils/config";
import Header from "../../components/Header/Header";
import axios from "axios";
import { EmailAddress } from "../../context/EmailAddress";
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const { emailAddress } = useContext(EmailAddress);
  const [data, setData] = useState({
    newPassword: undefined,
    newPassword2: undefined,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    if (data.newPassword !== data.newPassword2) {
      alert('Passwords do not match. Please re-enter.');
      return;
    }

    const newDataToSend = {
      email: emailAddress,
      newPassword: data.newPassword,
    }
    await axios.post(`${BASE_URL}/users/changePassword`, newDataToSend)
      .then(res => {
        alert('Password Changed succssefully.');
        navigate('/login')
      })
      .catch(err => {
        alert(err.response.data.message);
      })
  };

  return (
    <>
      <Header />
      <section>
        <Container>
          <Row>
            <Col lg="8" className="m-auto">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img d-flex align-items-center justify-content-center">
                  <img src={changePasswordImg} alt="" />
                </div>
                <div className="login__form">
                  <div className="user">
                    <img src={userIcon} alt="" />
                  </div>
                  <h2 className="mb-4">Change Password</h2>
                  <Form onSubmit={handleClick}>
                    <FormGroup>
                      <input
                        type="password"
                        placeholder={t('Password_PlaceHolder')}
                        required
                        id="newPassword"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="password"
                        placeholder="Re-enter New Password ------ change"
                        required
                        id="newPassword2"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      {t('SubmitButton')}
                    </Button>
                  </Form>
                  <p>
                  {t("Don't have any account?")} <Link to="/register">{t('Register_Link')}</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ChangePassword;
