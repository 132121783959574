import React, { useState, useEffect, useRef } from "react";
import "../styles/tour.css";
import SearchBar from "./../shared/SearchBar";
import TourCard from "./../shared/TourCard";
import Newsletter from "./../shared/Newsletter";
import { Container, Col, Row } from "reactstrap";
import Header from "../components/Header/Header";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../utils/config";
import CallUsBar from "../components/callus-bar/CallUsBar";
import axios from "axios";
import experienceImg from "../assets/images/experience.png";
import heroImgEGY from "../assets/images/hero-img03.jpg";
import heroImgUAE from "../assets/images/hero-img01.jpg";
import heroImgKSA from "../assets/images/hero-img02.jpg";
import heroImgOTHER from "../assets/images/v3.jpg";
import { useTranslation } from 'react-i18next';
import WhatYoullReceive from "../shared/WhatYoullReceive";
import ExperienceSection from "../shared/ExperienceSection";
import WhatOurFansSay from "../shared/WhatOurFansSay";
import ToursCarousel from "../components/Hero-Carousel/ToursCarousel";
import { useNavigate } from "react-router-dom";
import CustomModal from "../components/TripModal/CustomiseYourTripModal";

const Tours = () => {
  const searchSectionRef = useRef(null);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);
  const [tourData, setTourData] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("basicData");
  const [roomNull, setRoomNull] = useState(false)
  const [cutomiseTripData, setCutomiseTripData] = useState({
    destination: "",
    date: "",
    flexible: false,
    tripLength: 1,
    rooms: [{ _id: 1, bedTypes: "", children: 0, adults: 0 }],
    budget: 1,
    includeInternationalFlights: false,
    clientPreferences: "",
    email: '',
    firstName: '',
    lastName: '',
    phone: "",
    additionalNotes: '',
    state: 'Pending'
  });
  const [formErrors, setFormErrors] = useState({
    destination: null, date: null, tripLength: null,
    budget: null, includeInternationalFlights: null,
    clientPreferences: null, email: null, firstName: null,
    lastName: null, phone: null, additionalNotes: null,
    ...cutomiseTripData?.rooms.reduce((acc, _, index) => ({
      ...acc,
      [`room${index + 1}`]: {
        bedTypes: null, children: null, adults: null,
      },
    }), {}),
  });


  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  let backgroundImage = heroImgOTHER
  let title = window.location.pathname;
  let imageTitle = t('Tours_AllTours_ImageTitle')
  let imageDesc = t('Tours_AllTours_ImageSubTitle')
  let overview1 = t('Tours_AllTours_Overview_Paragraph1')
  let overview2 = t('Tours_AllTours_Overview_Paragraph2')

  if (title === "/tours/uae") {
    title = "United Arab Emirates";
    backgroundImage = heroImgUAE
    imageTitle = t('Tours_UAE_ImageTitle')
    imageDesc = t('Tours_UAE_ImageSubTitle')
    overview1 = t('Tours_UAE_Overview_Paragraph1')
    overview2 = t('Tours_UAE_Overview_Paragraph2')
  } else if (title === "/tours/ksa") {
    title = "Saudi Arabia";
    backgroundImage = heroImgKSA
    imageTitle = t('Tours_KSA_ImageTitle')
    imageDesc = t('Tours_KSA_ImageSubTitle')
    overview1 = t('Tours_KSA_Overview_Paragraph1')
    overview2 = t('Tours_KSA_Overview_Paragraph2')
  } else if (title === "/tours/egypt") {
    title = "Egypt";
    backgroundImage = heroImgEGY
    imageTitle = t('Tours_EGYPT_ImageTitle')
    imageDesc = t('Tours_EGYPT_ImageSubTitle')
    overview1 = t('Tours_EGYPT_Overview_Paragraph1')
    overview2 = t('Tours_EGYPT_Overview_Paragraph2')
  } else {
    title = "All Tours";
    backgroundImage = heroImgOTHER
    imageTitle = t('Tours_AllTours_ImageTitle')
    imageDesc = t('Tours_AllTours_ImageSubTitle')
    overview1 = t('Tours_AllTours_Overview_Paragraph1')
    overview2 = t('Tours_AllTours_Overview_Paragraph2')
  }

  const [tours, setTours] = useState([])
  const [loading, setLoding] = useState('')
  const [error, setError] = useState('')
  const [countryData, setCountryData] = useState({
    country: "",
    images: [""],
    imgDesc: imageDesc,
    imgTitle: imageTitle,
    // overview_pragraph1: overview1,
    // overview_pragraph2: overview2
  })

  useEffect(() => {
    if (title === 'Egypt' || title === 'Saudi Arabia' || title === 'United Arab Emirates') {
      axios.get(`${BASE_URL}/tours/search/getToursByCountry/${title}`)
        .then((res) => {
          setTours(res.data.data)
          setLoding(res.loading)
          setError(res.error)
        })
        .catch(err => {})
    } else {
      axios.get(`${BASE_URL}/tours/`)
        .then((res) => {
          const data = res.data.data
          setTours(data);
          setLoding(res.loading)
          setError(res.error)
        })
        .catch(err => {})
    }
    axios.get(`${BASE_URL}/countries/${title}`)
      .then((res) => {
        setCountryData({
          country: res.data.country,
          images: res.data.images,
          imgDesc: imageDesc,
          imgTitle: imageTitle,
          // overview_pragraph1: overview1,
          // overview_pragraph2: overview2
        })
      })
      .catch(err => {})
  }, [title])

  useEffect(() => {
    setTourData(Object.keys(tours).map(key => ({
      _id: tours[key]?._id,
      photos: tours[key]?.photos || [heroImgOTHER],
      price: tours[key]?.price,
      priceForTravelAgents:tours[key ]?.priceForTravelAgents,
      reviews: tours[key]?.reviews,
      featured: tours[key]?.featured,
      title: currentLanguage === 'Ar' ? tours[key]?.title_Ar : currentLanguage === 'Es' ? tours[key]?.title_Es : tours[key]?.title,
      city: currentLanguage === 'Ar' ? tours[key]?.city_Ar : currentLanguage === 'Es' ? tours[key]?.city_Es : tours[key]?.city_En,
    })));
  }, [tours, currentLanguage]);



  useEffect(() => {
    const pages = Math.ceil(tourCount / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page, tourCount, tours]);


  const handleShowModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleChange = (e) => {
    const { id, value, type, checked, selectedIndex, options } = e.target;
    // Handle different input types
    switch (type) {
      case 'checkbox':
        setCutomiseTripData((prevState) => ({
          ...prevState,
          [id]: checked,
        }));
        break;
      case 'select-one':
        setCutomiseTripData((prevState) => ({
          ...prevState,
          [id]: options[selectedIndex].value,
        }));
        break;
      case 'number':
        setCutomiseTripData((prevState) => ({
          ...prevState,
          [id]: parseInt(value),
        }));
        break;
      default:
        setCutomiseTripData((prevState) => ({
          ...prevState,
          [id]: value,
        }));
    }
  }

  const handleRoomChange = (e, roomIndex) => {
    const { id, value } = e.target;
    const updatedRooms = [...cutomiseTripData?.rooms];
    updatedRooms[roomIndex] = {
      ...updatedRooms[roomIndex],
      [id]: value,
    };
    setCutomiseTripData((prevState) => ({
      ...prevState,
      rooms: updatedRooms,
    }));

  };


  const handleClickNext = () => {
    const tabs = ["basicData", "How many people are traveling?", "budget", "userData"];
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex < tabs?.length - 1) {
      setActiveTab(tabs[currentIndex + 1]);
    }
  }

  const handleClickPrevious = () => {
    const tabs = ["basicData", "How many people are traveling?", "budget", "userData"];
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  }

  const handleClickSend = () => {

    const excludedKeys = ['additionalNotes'];
    const hasEmptyFields = Object.entries(cutomiseTripData).some(([key, value]) => {
      if (!excludedKeys.includes(key)) {
        return typeof value === 'string' && value.trim() === '';
      }
      return false;
    });

    if (hasEmptyFields) {
      const roomErrors = cutomiseTripData?.rooms.map((room, index) => ({
        bedTypes: room.bedTypes.trim() === '' ? t('This field is required') : null,
        children: room.children === 0 ? t('This field is required') : null,
        adults: room.adults === 0 ? t('This field is required') : null,
      }));
      const allRoomErrors = roomErrors.reduce((acc, roomError, index) => ({
        ...acc,
        [`room${index + 1}`]: roomError,
      }), {});
      const anyRoomErrorNotNull = Object.keys(allRoomErrors).some(key => {
        const roomError = allRoomErrors[key];
        return Object.values(roomError).some(value => value !== null);
      });
      setRoomNull(anyRoomErrorNotNull)


      setFormErrors(prevState => ({
        ...prevState,
        destination: cutomiseTripData?.destination === '' ? t('This field is required') : null,
        date: cutomiseTripData?.date === '' ? t('This field is required') : null,
        tripLength: cutomiseTripData?.tripLength === '' ? t('This field is required') : null,
        budget: cutomiseTripData?.budget === '' ? t('This field is required') : null,
        clientPreferences: cutomiseTripData?.clientPreferences === '' ? t('This field is required') : null,
        email: cutomiseTripData?.email.trim() === '' ? t('This field is required') : null,
        firstName: cutomiseTripData?.firstName.trim() === '' ? t('This field is required') : null,
        lastName: cutomiseTripData?.lastName.trim() === '' ? t('This field is required') : null,
        phone: cutomiseTripData?.phone === '' ? t('This field is required') : null,
        ...allRoomErrors,
      }));
      return;
    } else {
      setFormErrors({})
    }




    axios.post(`${BASE_URL}/cutomiseTrips`, cutomiseTripData)
      .then((res) => {
        handleCloseModal();
        setCutomiseTripData({
          destination: "",
          date: "",
          flexible: false,
          tripLength: 1,
          rooms: [{ bedTypes: "", children: 0, adults: 0 }],
          budget: 1,
          includeInternationalFlights: false,
          clientPreferences: "",
          email: '',
          firstName: '',
          lastName: '',
          phone: "",
          additionalNotes: '',
          state: 'Pending'
        })
      })
      .catch(err => {})
  }

  const scrolltosearchbar = () => {
    if (searchSectionRef.current) {
      searchSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Header />
      <ToursCarousel images={countryData.images || [heroImgUAE, heroImgKSA, heroImgEGY]} text={countryData.imgDesc || 'A New Adventure Awaits You!'} scrolltosearchbar={scrolltosearchbar} />
      {/* <div className="p-0 m-0 heroSection"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${countryData.image}) no-repeat center center`,
        }}>
        <div className="top h-100 pb-5 flex-column justify-content-end">
            <i><h2>
              {countryData.imgDesc || 'A New Adventure Awaits You!'}
            </h2></i>
            <button className="btn buttonBookNow" onClick={scrolltosearchbar}>
              {t('Tours_ImageButton')}
            </button>
        </div>
      </div> */}
      <section>
        <Container>
          <div
            className="box overviewContainer"
          >
            <div className="left">
              <h2 style={{ color: "rgb(219, 158, 54)" }}>
                {t('Tours_OverviewTitle')}
              </h2>
              <p>
                {/* {countryData.overview_pragraph1} */}
                {overview1}
              </p>
              <p>
                {/* {countryData.overview_pragraph2} */}
                {overview2}
              </p>
            </div>
            <div className="right" style={{ width: "150%" }} >
              <div className="progressGroup">
                <p className="px-4">
                  {t('Tours_Overview_Progressbar_Item1')}
                </p>
                <div className="progress mb-3" style={{ width: "50%" }}>
                  <div className="progress-bar" role="progressbar" style={{ width: "70%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div className="progressGroup">
                <p className="px-4">
                  {t('Tours_Overview_Progressbar_Item2')}
                </p>
                <div className="progress mb-3" style={{ width: "50%" }}>
                  <div className="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div className="progressGroup">
                <p className="px-4">
                  {t('Tours_Overview_Progressbar_Item3')}
                </p>
                <div className="progress mb-3" style={{ width: "50%" }}>
                  <div className="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div className="progressGroup">
                <p className="px-4">
                  {t('Tours_Overview_Progressbar_Item4')}
                </p>
                <div className="progress mb-3" style={{ width: "50%" }}>
                  <div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <div ref={searchSectionRef}>
              <SearchBar />
            </div>
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          {loading && <h4 className="text-center pt-5">Loading.....</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}
          {!loading && !error && (
            <Row>
              {tourData?.map((item) => (
                <Col lg="3" md="6" sm="6" className="mb-4" key={item._id}>
                  <TourCard tour={item} />
                </Col>
              ))}
              <Col lg="3" md="6" sm="6" className="mb-4">
                <div className="CreateCard tour__card">
                  <button className="btn buttonCreateTour" onClick={handleShowModal}>{t('Customise Your Trip')}</button>
                </div>
              </Col>
              <CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                handleChange={handleChange}
                handleRoomChange={handleRoomChange}
                handleClickSend={handleClickSend}
                title={t('Customise Your Trip')}
                cutomiseTripData={cutomiseTripData}
                setCutomiseTripData={setCutomiseTripData}
                handleClickNext={handleClickNext}
                handleClickPrevious={handleClickPrevious}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                formErrors={formErrors}
                roomNull={roomNull}
              />
              <Col lg="12">
                <div className="pagination d-flex align-items-center justify-content-center mt-4 gap-3">
                  {pageCount?.length > 0 && [...Array(pageCount).keys()].map((number) => (
                    <span
                      key={number}
                      onClick={() => setPage(number)}
                      className={page === number ? "active__page" : ""}
                    >
                      {number + 1}
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
      <section style={{ backgroundColor: "#D8E8DE" }} className="box">
        <WhatYoullReceive />
      </section>
      <section>
        <ExperienceSection />
      </section>
      {/* ======== experience tour section end ======== */}

      {/* ======== testmonial section start ======== */}
      <section style={{ backgroundColor: "#D8E8DE" }} className="box" ></section>
      <section>
        <WhatOurFansSay />
      </section>
      {/* ======== testmonial section end ======== */}
      {/* <Newsletter /> */}
      <CallUsBar />
    </>
  );
};

export default Tours;
