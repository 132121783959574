import React, { useContext } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import calculateAvgRating from "../utils/avgRating";
import { useTranslation } from 'react-i18next';
import "./tour-card.css";
import { AuthContext } from "../context/AuthContext";

const TourCard = ({ tour }) => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const { _id, title, city, photos, price, priceForTravelAgents, featured, reviews } = tour;

  const { totalRating, avgRating } = calculateAvgRating(reviews);
  return (
    <div className="tour__card" id="tour__card">
      <Card>
        <div className="tour__img">
          <img height='20%' src={photos[0]} alt="tour-img" />
          {featured && <span>{t('Featured')}</span>}
        </div>

        <CardBody>
          <div className="card__top d-flex align-items-center justify-content-between">
            <span className="tour__location d-flex align-items-center gap-1">
              <i className="ri-map-pin-line"></i> {city}
            </span>
            <span className="tour__rating d-flex align-items-center gap-1">
              <i className="ri-star-fill"></i> {avgRating === 0 ? null : avgRating}
              {totalRating === 0 ? (
                "Not rated"
              ) : (
                <span>({reviews.length})</span>
              )}
            </span>
          </div>
          <h5 className="tour__title">
            <Link to={`/tours/${_id}`}>{title}</Link>
          </h5>
          <div className="card__bottom d-flex align-items-center justify-content-between mt-3">
            <h5>
              <span>
                {t('Tour_BeforePrice')}
              </span>
              {
                user?.accountType === 'TravelAgent' ?
                  <div>
                    <span style={{textDecoration: ' line-through'}}>${price}</span>
                    {' '}
                    ${priceForTravelAgents}
                  </div>
                  :
                  <div>${price}</div>

              }
              {/* <span>/person</span> */}
            </h5>
            <button className="btn booking__btn">
              <Link to={`/tours/${_id}`}>
                {t('TourCard_Button')}
              </Link>
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TourCard;
