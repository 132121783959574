import React, { useRef, useEffect, useContext } from 'react'
import { Container, Row, Button } from 'reactstrap'
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/images/logo.png';
import "./header.css";

import { AuthContext } from './../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../context/Language';

const Header = () => {

  const headerRef = useRef(null)
  const menuRef = useRef(null)
  const navigate = useNavigate()
  const { user, dispatch } = useContext(AuthContext)
  const { t, i18n } = useTranslation();
  const segments = window.location.pathname.split('/').filter(segment => segment.trim() !== '')
  const params = segments[segments.length - 1];
  const { language, setLanguage } = useContext(LanguageContext);

  const nav__links = [
    // {
    //   path: '/home',
    //   display: t("Header_Home")
    // },
    {
      path: '/about',
      display: t('Header_About')
    },
    {
      path: '/tours',
      display: t('Header_Tours')
    },
  ]


  const logout = () => {
    dispatch({ type: 'LOGOUT' })
    navigate('/')
    window.location.reload();
  }

  // const stickyHeaderFunc = () => {
  //   window.addEventListener('scroll', () => {
  //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //       headerRef.current.classList.add('sticky__header')
  //     } else {
  //       headerRef.current.classList.remove('sticky__header')
  //     }
  //   })
  // }
  const HeaderFunc = () => {
    window.addEventListener('scroll', () => {
      // Store the ref value in a variable and Check if the ref is null and return if it is
      const headerElement = headerRef.current;
      if (!headerElement) return;

      // if (window.innerWidth <= 992) {
      //   headerRef.current.classList.add('headerView')
      //   headerRef.current.style.opacity = 1;
      //   return;
      // }

      if (params === 'home') {
        if ((document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) && window.scrollY > window.innerHeight) {
          headerRef.current.classList.add('headerView')
        } else {
          headerRef.current.classList.remove('headerView')
        }
      }
      else {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
          headerRef.current.classList.add('headerView')
        } else {
          headerRef.current.classList.remove('headerView')
        }
      }

    })
  }

  useEffect(() => {
    HeaderFunc();

    return window.removeEventListener('scroll', HeaderFunc)
  }, [window.scrollY, window.innerWidth])

  const toggleMenu = () => menuRef.current.classList.toggle('show__menu')
  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav__wrapper d-flex align-items-center justify-content-between">
            {/*===========logo===========*/}
            <div className="logo">
              <img src={logo} alt='' />
            </div>
            {/*===========logo end===========*/}
            {/*===========menu start=========*/}
            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <ul className="menu d-flex align-items-center gap-5">
                <li className="nav__item">
                  <a href="/home" className={navClass => navClass.isActive ? 'active__link' : ''} style={{ textDecoration: 'none' }}>
                    {t('Header_Home')}
                  </a>
                </li>
                {
                  nav__links.map((item, index) => (
                    <li className="nav__item" key={index}>
                      <NavLink to={item.path} className={navClass => navClass.isActive ? 'active__link' : ''}>{item.display}</NavLink>
                    </li>
                  ))
                }
              </ul>
            </div>
            {/*===========menu end=========*/}

            <div className="nav__right d-flex align-items-center">
              <div className="nav__btns d-flex align-items-center ">

                {
                  user ? <>
                    <Button className='username btn secondary__btn'><Link>{user.username}</Link></Button>
                    <Button className='btn primary__btn'
                      style={{ width: 'max-content' }}
                      onClick={logout}>{t('Header_Logout')}</Button>
                  </> : <>
                    <Button className='btn secondary__btn'><Link to='/login'>{t('Header_Login')}</Link></Button>
                    <Button className='btn primary__btn'><Link to='/register'>{t('Header_Register')}</Link></Button>
                  </>
                }


              </div>
              {/* <div id="google_translate_element" style={{lineHeight: "auto"}}>  </div> */}
              <Dropdown>
                <Dropdown.Toggle className='dropdown-toggle' style={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  color: 'white',
                }} id="dropdown-basic">
                  <FontAwesomeIcon icon={faGlobe} className='mx-1' />
                </Dropdown.Toggle >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => { i18n.changeLanguage('Ar'); setLanguage('Ar'); localStorage.setItem('meaTravelLanguage','Ar') }} value='Ar' >عربي</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => { i18n.changeLanguage('En'); setLanguage('En'); localStorage.setItem('meaTravelLanguage','En')  }} value="En" >English</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => { i18n.changeLanguage('Es'); setLanguage('Es'); localStorage.setItem('meaTravelLanguage','Es')  }} value="Es" >Español</Dropdown.Item>
                  <Dropdown.Item ></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <span className="mobile__menu" onClick={toggleMenu}>
              <i className="ri-menu-line"></i>
            </span>
          </div>
        </Row>
      </Container>
    </header>
  )
}

export default Header;
