import React, {useEffect} from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import '../styles/thank-you.css'
import Header from '../components/Header/Header'
import { useTranslation } from 'react-i18next';

export const ThankYou = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Header />
    <section>
      <Container>
        <Row>
          <Col lg="12" className='pt-5 text-center'>
            <div className="thank__you">
              <span>
                <i class="ri-checkbox-circle-line"></i>
              </span>
              <h1 className="mb3 fw-semibold">
                {t('ThankyouPage_Title')}
              </h1>
              <h3 className="mb-4">
                {t('ThankyouPage_Description')}
              </h3>

              <Button className="btn primary__btn w-25">
                <a href="/home">
                  {t('ThankyouPage_Button')}
                </a>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}
