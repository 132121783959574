import React, { useEffect, useRef } from "react";

const TripAdvisorWidget = () => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src =
  //     "https://www.jscache.com/wejs?wtype=cdsratingsonlynarrow&amp;uniq=318&amp;locationId=27690660&amp;lang=en_US&amp;border=true&amp;shadow=true&amp;display_version=2";
  //   script.setAttribute("data-loadtrk", "");
  //   script.onload = function () {
  //     this.loadtrk = true;
  //   };
  //   document.body.appendChild(script);

  //   return () => {
  //     // Cleanup script when component unmounts
  //     document.body.removeChild(script);
  //   };
  // }, []); 

  // const scriptRoot = useRef();  // gets assigned to a root node
  // const script = `<script async
  // src="https://www.jscache.com/wejs?wtype=cdsratingsonlynarrow&amp;uniq=318&amp;locationId=27690660&amp;lang=en_US&amp;border=true&amp;shadow=true&amp;display_version=2"
  // data-loadtrk onload="this.loadtrk=true"></script>`

  // useEffect(() => {
  //   const range = document.createRange()
  //   const documentFragment = range.createContextualFragment(script)
  //   scriptRoot.current.append(documentFragment)
  // }, [])

  return (
    <div id="TA_cdsratingsonlynarrow318" className="TA_cdsratingsonlynarrow w-100"
    // ref={scriptRoot}
    >
      <ul id="iCGK6mRNtfHw" className="TA_links s3aSyWx3lqK w-50" style={{ display: "flex", justifyContent: "center" }}>
        <li id="6IdwJ7lJke" className="pTvru7Mvr">
          <a target="_blank" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: "100%" }} href="https://www.tripadvisor.com/Attraction_Review-g294201-d27690660-Reviews-Mea_Travel-Cairo_Cairo_Governorate.html">
            <img src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg" alt="TripAdvisor" style={{ maxWidth: "100%" }} />
          </a>
        </li>
      </ul>
      
    </div>
  );
};

export default TripAdvisorWidget;
