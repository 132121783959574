import { Col, Row } from 'reactstrap'
import './callusbar.css'
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { React, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function CallUsBar() {
  const [contactInfo, setContactInfo] = useState('');
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/contactInfo/`);
        const firstContactInfo = response.data.find(info => info.showInHomePage === true);
        if (firstContactInfo)
          setContactInfo(firstContactInfo.phone);
      } catch (error) {}
    };
    fetchData();
  }, [contactInfo]);

  return (
    <section>
      <div className="quote__banner">
        <Row>
          <Col lg="4" md="4" style={{ textAlign: "center" }}>
            <p className="call__text">
              {t('CallUsBar_Left')}
            </p>
          </Col>
          <Col lg="4" md="4" style={{ textAlign: "center" }}>
            <p className="mb-0 callus" style={{ color: "#db9e36" }}>
              <i className="ri-phone-fill"></i>
              {" "}
              <a href={`tel:+20${contactInfo}`}>
                {t('CallUsBar_Center')}
                {" "}
                 +20 {contactInfo}
              </a>
            </p>
          </Col>
          <Col lg="4" md="4" style={{ textAlign: "center" }}>
            <a
            href={`https://wa.me/+20${contactInfo}?text={message}`}
              className="btn primary__btn callusbtn"
              style={{ background: "#db9e36" }}
            >
              {t('CallUsBar_Button')}
            </a>
          </Col>
        </Row>
      </div>
    </section>
  );
}