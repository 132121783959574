import React from 'react'
import '../styles/home.css'
import { Container, Row, Col } from 'reactstrap'
import Subtitle from './Subtitle';
import ServiceList from '../services/ServiceList';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const WhatYoullReceive = () => {
    const { t } = useTranslation();

    const scrollToFooter = () => {
        const footer = document.getElementById('footer');
        footer.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    return (
        <Container>
            <Row>
                <Col lg="12" className="mb-5">
                    <Subtitle subtitle={t('RecieveSection_Title')} />
                    <h2 className="featured__tour-title">
                        {t('RecieveSection_SubTitle')}
                    </h2>
                </Col>
                <ServiceList />
                <Col className='d-flex align-items-center justify-content-end gap-2 btn border-0 pb-5' onClick={scrollToFooter}>
                    <div className='fw-bold'>{t('RecieveSection_Recommendation_Text')} </div>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Col>
            </Row>
        </Container>
    )
}

export default WhatYoullReceive;
